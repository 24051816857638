<template>
  <div class="manager-reports">
    <div class="d-sm-flex justify-content-between mb-3">
      <h2 class="page-title">Reports</h2>
      <div class="d-flex justify-content-between">
        <DateRangePicker :value="filters.date" @input="dateHandler" />

        <!-- <flat-pickr
          v-model="filters.date"
          :config="{
            mode: 'range',
            defaultDate: ['2022-01-01', today],
            locale: {
              rangeSeparator: '/',
            },
          }"
          @on-close="closeDatepicker"
          class="form-control datepicker mr-3"
        ></flat-pickr> -->
        <div>
          <v-select
            class="reports-filter-dropdown"
            :class="{ selected: filters.interval }"
            v-model="filters.interval"
            :options="intervals"
            :searchable="false"
            placeholder="Filters"
            :clearable="false"
          ></v-select>
        </div>
      </div>
    </div>
    <div v-if="summary" class="container-fluid p-0">
      <div class="row">
        <div class="col-md-4 d-flex">
          <card header-classes="bg-transparent" id="first-fullscreen">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">Dispositions</h5>
                </div>
              </div>
            </template>
            <button
              type="button"
              class="btn btn-tiny mb-5"
              @click="toggleApi('first-fullscreen')"
            >
              <i class="fa fa-expand"></i>
              Fullscreen
            </button>
            <div class="chart-area" :class="{ fullscreen: isFullscreen }">
              <canvas ref="chart" :id="salesChartID"></canvas>
            </div>
            <template v-slot:footer>
              <div class="legend mt-2">
                <div
                  v-for="(item, index) in summary.dispositions"
                  :key="index"
                  class="badge-dot badge-md"
                >
                  <i
                    :class="CHART_COLORS[index]?.className || 'bg-primary'"
                  ></i>
                  <span class="status">{{ item.disposition || "null" }}</span>
                  <strong>{{ item.records }}</strong>
                </div>
              </div>
            </template>
          </card>
        </div>
        <div class="d-flex flex-grow-1" v-if="!user?.roles?.includes('AGENT')">
          <card header-classes="bg-transparent" id="second-fullscreen">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">Agents</h5>
                </div>
              </div>
            </template>
            <button
              type="button"
              class="btn btn-tiny mb-5"
              @click="toggleApi('second-fullscreen')"
            >
              <i class="fa fa-expand"></i>
              Fullscreen
            </button>
            <div class="chart-area" :class="{ fullscreen: isFullscreen }">
              <canvas ref="chart" :id="ordersChartID"></canvas>
            </div>
            <!-- <template v-slot:footer>
              <div class="legend">
                <div class="badge-dot badge-lg">
                  <i :class="`bg-orange`"></i>
                  <span class="status">Sum of Sold Calls</span>
                </div>
              </div>
            </template> -->
          </card>
        </div>
        <div class="d-flex flex-grow-1">
          <card header-classes="bg-transparent" id="third-fullscreen">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">Calls</h5>
                </div>
              </div>
            </template>
            <button
              type="button"
              class="btn btn-tiny mb-5"
              @click="toggleApi('third-fullscreen')"
            >
              <i class="fa fa-expand"></i>
              Fullscreen
            </button>
            <div class="chart-area" :class="{ fullscreen: isFullscreen }">
              <canvas ref="chart" :id="ordersChartID2"></canvas>
            </div>
            <!-- <template v-slot:footer>
              <div class="legend">
                <div class="badge-dot badge-lg">
                  <i :class="`bg-primary`"></i>
                  <span class="status">Sum of Sold Calls</span>
                </div>
              </div>
            </template> -->
          </card>
        </div>
      </div>
    </div>
    <calls-reports-table
      title="Calls Reporting"
      :headerLink="{ title: 'ExportCSV' }"
    />
    <contacts-reports-table
      title="Exportable details from contact form"
      :headerLink="{ title: 'ExportCSV' }"
    />
  </div>
</template>

<script>
import CallsReportsTable from "@/components/CallsReportsTable";
import ContactsReportsTable from "@/components/ContactsReportsTable";
import {
  ordersChart,
  pieChart,
  barChartStacked,
} from "../../components/theme-components/Charts/Chart";
import { mapActions, mapState } from "pinia";
import { useReportsStore } from "../../store/reportsStore";
import { CHART_COLORS } from "../../constants/charts";
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { DateTime } from "luxon";
import DateRangePicker from "../../components/DateRangePicker.vue";
import { useUserStore } from "@/store/userStore";

const intervals = [
  { label: "Hourly", value: "hour" },
  { label: "Daily", value: "day" },
  { label: "Weekly", value: "week" },
  { label: "Monthly", value: "month" },
  { label: "Yearly", value: "year" },
];

export default {
  name: "Reports",
  components: {
    CallsReportsTable,
    ContactsReportsTable,
    // flatPickr,
    DateRangePicker,
  },
  data() {
    return {
      date: null,
      status: "on-call",
      showPopup: true,
      isFullscreen: false,
      filters: {
        interval: intervals[0],
        date: ["2022-01-01", DateTime.now().toFormat("y-LL-dd")],
      },
      CHART_COLORS,

      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      ordersChartID2: "ordersChart2",
      ordersChartID3: "ordersChart3",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
      intervals,
    };
  },
  computed: {
    ...mapState(useReportsStore, ["summary"]),
    ...mapState(useUserStore, ["user"]),
    today() {
      return DateTime.now().toFormat("y-LL-dd");
    },
    dispositionsChart() {
      if (!this.summary.dispositions) return [];
      return {
        labels: this.summary.dispositions.map((z) => z.disposition),
        data: this.summary.dispositions.map((z) => z.records),
      };
    },
    agentsChart() {
      if (!this.summary.agents) return [];
      return {
        labels: this.summary.agents.map((z) => z.agent.name),
        data: this.summary.agents.map((z) => z.records),
      };
    },
    agentsStackedChart() {
      if (!this.summary.agents) return [];

      const result = this.summary;

      let unique_agents = [
        ...new Set(result.agents.map((obj) => obj.agent.username)),
      ];
      let unique_dispositions = [
        ...new Set(result.agents.map((obj) => obj.disposition)),
      ];

      const datasets = unique_dispositions.map((disposition) => {
        return {
          label: disposition || "null",
          data: unique_agents.map((username) => {
            const agent = result.agents.filter(
              (agent_obj) => agent_obj.agent.username === username
            );
            const records = agent.find((agentObj) => {
              return agentObj.disposition === disposition;
            });
            try {
              return records.records;
            } catch (error) {
              return 0;
            }
          }),
        };
      });
      return {
        labels: unique_agents.map(
          (username) =>
            result.agents.find((z) => {
              return z.agent.username === username;
            }).agent.name
        ),
        datasets,
      };
    },
    callsChart() {
      if (!this.summary.interval) return [];
      return {
        labels: this.summary.interval.map((z) => z.interval),
        data: this.summary.interval.map((z) => z.records),
      };
    },
  },
  watch: {
    "filters.interval"() {
      this.fetchAfterFiltersChange();
    },
  },
  mounted() {
    this.fetchSummary({
      start: this.filters.date[0],
      end: this.filters.date[1],
      interval: this.filters.interval.value || "hour",
    }).then(() => {
      if (this.$refs.chart) {
        this.callsChartModule = ordersChart(
          this.ordersChartID2,
          this.callsChart,
          "#6073E5"
        );
        this.agentsChartModule = barChartStacked(
          this.ordersChartID,
          this.agentsStackedChart
        );
        // this.agentsChartModule = ordersChart(
        //   this.ordersChartID,
        //   this.agentsChart
        // );
        this.dispositionsChartModule = pieChart(
          this.salesChartID,
          this.dispositionsChart
        );
      }
    });
  },
  methods: {
    ...mapActions(useReportsStore, ["fetchSummary"]),
    popupHandler(show) {
      this.showPopup = show;
    },
    toggleApi(ref) {
      this.$fullscreen.toggle(document.getElementById(ref), {
        callback: (isFullscreen) => {
          this.isFullscreen = isFullscreen;
        },
      });
    },
    // closeDatepicker() {
    //   this.$nextTick(() => {
    //     this.fetchAfterFiltersChange();
    //   });
    // },
    fetchAfterFiltersChange() {
      this.fetchSummary({
        start: this.filters.date[0],
        end: this.filters.date[1],
        interval: this.filters.interval.value || "hour",
      }).then(() => {
        if (this.$refs.chart) {
          setTimeout(() => {
            this.agentsChartModule.updateChart(this.agentsStackedChart);
            this.callsChartModule.updateChart(this.callsChart);
            this.dispositionsChartModule.updateChart(this.dispositionsChart);
          }, 50);
        }
      });
    },
    updateValues(val) {
      console.log(val);
    },
    dateHandler(val) {
      this.filters.date = val;
      this.$nextTick(() => {
        this.fetchAfterFiltersChange();
      });
    },
  },
};
</script>

<style lang="scss"></style>
