<template>
  <popup title="Add Payment Method" class="add-payment-popup">
    <hr />
    <Form @submit="submit" :validation-schema="schema">
      <base-input
        class="mb-3"
        alternative
        name="name"
        label="Card holder"
        placeholder="Card holder name"
      />
      <base-input
        v-maska="'#### #### #### ####'"
        class="mb-3"
        alternative
        name="number"
        label="Credit/debit card number"
        placeholder="Enter card number"
      />
      <div class="d-md-flex">
        <base-input
          v-maska="'##/##'"
          class="mb-3 mr-md-3"
          alternative
          name="date"
          label="Expiration date"
          placeholder="MM / YY"
        />
        <base-input
          v-maska="{ mask: 'ZZZ', tokens: { Z: { pattern: /[a-zA-Z0-9]/ } } }"
          class="mb-3 ml-md-3"
          alternative
          name="cvc"
          label="CVC"
          type="password"
          placeholder="***"
        />
      </div>
      <button :disabled="isFetching" type="submit" class="btn btn-primary mt-4">
        Confirm
      </button>
    </Form>
  </popup>
</template>

<script>
import Popup from "./Popup.vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import { mapActions, mapState } from "pinia";
import { usePaymentsStore } from "../../store/paymentsStore";

const toast = useToast();

export default {
  components: { Popup, Form },
  data() {
    return {
      schema: Yup.object().shape({
        name: Yup.string().required().label("Name"),
        number: Yup.string()
          .min(16)
          .required()
          .transform((value) => {
            return value ? value.replace(/\s/g, "") : value;
          })
          .label("Card Number"),
        date: Yup.string()
          .min(4)
          .required()
          .transform((value) => {
            return value ? value.replace(/\//g, "") : value;
          })
          .label("Expiration date"),
        cvc: Yup.string().min(3).required().label("CVC"),
      }),
    };
  },
  computed: {
    ...mapState(usePaymentsStore, ["promises"]),
    isFetching() {
      return this.promises.addPayment.isFetching;
    },
  },
  methods: {
    ...mapActions(usePaymentsStore, ["addPayment"]),
    async submit({ number, date, cvc, name }) {
      try {
        const payload = {
          card: {
            number: number.replace(/\s/g, ""),
            cvc,
            exp_month: date.split("/")[0],
            exp_year: `20${date.split("/")[1]}`,
          },
          billing_details: {
            name,
          },
          default: false,
        };
        await this.addPayment(payload);
        this.$emit("close");
        toast.success("Payment Method added successfully");
      } catch (e) {
        toast.error(e);
      }
    },
  },
};
</script>
