<template>
  <input
    type="text"
    name="daterange"
    :value="`${value[0]} - ${value[1]}`"
    readonly
    class="form-control datepicker mr-3"
  />
</template>

<script>
const $ = require("jquery");
import moment from "moment";
window.jQuery = window.$ = $;
window.moment = moment;
const daterangepicker = require("daterangepicker");
window.daterangepicker = daterangepicker;

export default {
  props: {
    value: {
      type: Array,
      default() {
        return ["2022-01-01", moment().format("YYYY-MM-DD")];
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      $('input[name="daterange"]').daterangepicker(
        {
          opens: "left",
          startDate: moment("2022-01-01"),
          endDate: moment(),
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        },
        (start, end) => {
          this.$emit("input", [
            start.format("YYYY-MM-DD"),
            end.format("YYYY-MM-DD"),
          ]);
        }
      );
    },
  },
};
</script>
