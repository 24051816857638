<template>
  <div>
    <h2 class="page-title">Reports</h2>
    <calls-reports-table
      title="Calls Reporting"
      :headerLink="{ title: 'ExportCSV' }"
    />
    <contacts-reports-table
      title="Exportable details from contact form"
      :headerLink="{ title: 'ExportCSV' }"
    />
  </div>
</template>

<script>
import CallsReportsTable from "@/components/CallsReportsTable";
import ContactsReportsTable from "@/components/ContactsReportsTable";

export default {
  name: "overview",
  components: {
    CallsReportsTable,
    ContactsReportsTable,
  },
  data() {
    return {
      status: "on-call",
      showPopup: true,
    };
  },
  methods: {
    popupHandler(show) {
      this.showPopup = show;
    },
  },
};
</script>

<style lang="scss"></style>
