<template>
  <card class="active-call">
    <template #header>
      <div class="active-call__header">
        <div class="active-call__title">Current Active Call</div>
        <div class="active-call__duration">
          <div class="active-call__duration--key">Call Duration</div>
          <div class="active-call__duration--value">01 Hour, 45 minutes</div>
        </div>
      </div>
    </template>

    <div class="active-call-table__wr" v-if="dispositioningData">
      <div class="active-call-table">
        <div class="active-call-table__row">
          <div class="active-call-table__col">InBound Call Id</div>
          <div class="active-call-table__col">
            {{ dispositioningData.call_id }}
          </div>
        </div>
        <div class="active-call-table__row">
          <div class="active-call-table__col">Contact Card</div>
          <div class="active-call-table__col"></div>
        </div>
        <div class="active-call-table__row">
          <div class="active-call-table__col">Call Start</div>
          <div class="active-call-table__col">
            {{ parseTime(dispositioningData.call_start) }}
          </div>
        </div>
        <div class="active-call-table__row">
          <div class="active-call-table__col">Call End</div>
          <div class="active-call-table__col">
            {{
              dispositioningData.call_end
                ? parseTime(dispositioningData.call_end)
                : "-"
            }}
          </div>
        </div>
        <div class="active-call-table__row">
          <div class="active-call-table__col">State</div>
          <div class="active-call-table__col">
            {{ dispositioningData.state }}
          </div>
        </div>
        <div class="active-call-table__row">
          <div class="active-call-table__col">Agent</div>
          <div class="active-call-table__col">
            {{ dispositioningData.agent }}
          </div>
        </div>
        <div class="active-call-table__row">
          <div class="active-call-table__col">Revenue</div>
          <div class="active-call-table__col">
            {{ dispositioningData.revenue }}
          </div>
        </div>
        <div class="active-call-table__row">
          <div class="active-call-table__col">Created at</div>
          <div class="active-call-table__col">
            {{ parseDate(dispositioningData.created_at) }}
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "../store/userStore";
import { DateTime } from "luxon";

export default {
  computed: {
    ...mapState(useUserStore, ["dispositioningData"]),
  },
  methods: {
    parseTime(date) {
      const dt = DateTime.fromISO(date);
      return dt.toFormat("hh:mm a");
    },
    parseDate(date) {
      const dt = DateTime.fromISO(date);
      return dt.toFormat("d MMM y");
    },
  },
};
</script>
