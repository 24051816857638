import { defineStore } from "pinia";
import { axiosService } from "@/services/baseService";
import { useUserStore } from "./userStore";

export const usePaymentsStore = defineStore("payments", {
  state: () => {
    return {
      payments: [],
      promises: {
        addPayment: {
          isFetching: false,
        },
        setDefaultPayment: {
          isFetching: false,
        },
        getPayments: {
          isFetching: false,
        },
      },
    };
  },
  actions: {
    async addPayment(payload) {
      if (this.promises.isFetching) return;

      try {
        this.promises.addPayment.isFetching = true;
        const store = useUserStore();
        await axiosService.post(
          `api/user/${store.baseUser.uuid}/add_payment_method`,
          payload,
          {
            auth: true,
          }
        );
        this.promises.addPayment.isFetching = false;
      } catch (e) {
        this.promises.addPayment.isFetching = false;
        throw (
          e.response?.data?.message ||
          "Something went wrong. Please, try again later."
        );
      }
    },
    async setDefaultPayment(id) {
      if (this.promises.isFetching) return;

      try {
        this.promises.setDefaultPayment.isFetching = true;
        const store = useUserStore();
        await axiosService.post(
          `api/user/${store.baseUser.uuid}/set_default_payment_method`,
          { id },
          {
            auth: true,
          }
        );
        this.promises.setDefaultPayment.isFetching = false;
        this.payments = this.payments.map((item) => ({
          ...item,
          default: item.id === id,
        }));
      } catch (e) {
        this.promises.setDefaultPayment.isFetching = false;
        throw new Error(e);
      }
    },
    async getPayments() {
      if (this.promises.getPayments.isFetching) return;

      try {
        this.promises.getPayments.isFetching = true;
        const store = useUserStore();
        let resp = await axiosService.get(
          `api/user/${store.baseUser.uuid}/payment_methods`,
          {
            auth: true,
          }
        );
        this.payments = resp.data;
        this.promises.getPayments.isFetching = false;
      } catch (e) {
        this.promises.getPayments.isFetching = false;
        throw new Error(e);
      }
    },
  },
});
