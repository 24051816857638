<template>
  <div class="d-flex align-items-xl-center">
    <h2 class="page-title flex-grow-1">Settings / Filters and Destination</h2>
    <button
      :disabled="isFetching"
      class="btn btn-primary"
      type="button"
      @click="submitSchedule"
    >
      Save Changes
    </button>
  </div>
  <div class="card p-0">
    <!--<div class="mb-4 form-element">
      <label class="col-form-label">Vertical</label>
      <div class="form-element__input">
        <v-select
          multiple
          v-model="vertical"
          :options="verticalOptions"
          label="name"
          value="uuid"
          :closeOnSelect="false"
          placeholder="Select"
        ></v-select>
      </div>
    </div>-->
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-start mb-4">
        <!--<div class="form-element">
        <label class="col-form-label">Accepted States</label>
        <div class="form-element__input">
          <v-select
            multiple
            v-model="states"
            :options="statesOption"
            :closeOnSelect="false"
            placeholder="Select"
          ></v-select>
        </div>
      </div>-->

      </div>
      <div
        class="mb-4 form-element"
        v-if="manager_lock.includes('settings.verticals')"
      >
        <label class="col-form-label">Vertical</label>
        <div class="form-element__button" v-for="(v, key) in vertical">
          <base-button type="neutral">{{ v.name }}</base-button>
        </div>
      </div>
      <div class="mb-4 form-element" v-else>
        <label class="col-form-label">Vertical</label>
        <div class="form-element__input">
          <v-select
            multiple
            v-model="vertical"
            :options="verticalOptions"
            label="name"
            value="uuid"
            :closeOnSelect="false"
            placeholder="Select"
          ></v-select>
        </div>
      </div>
      <!--Can't edit accepted states-->
      <div
        class="mb-4 form-element"
        v-if="manager_lock.includes('settings.filters.states')"
      >
        <label class="col-form-label">States</label>
        <div>
          <span
            class="mb-4"
            v-for="state in states"
            style="margin-right: 0.25em"
          >
            <base-button type="neutral">{{ state }}</base-button>
          </span>
        </div>
      </div>
      <!--Can edit accepted states-->
      <div class="mb-4 form-element" v-else>
        <label class="col-form-label">Accepted States</label>
        <div class="form-element__input">
          <v-select
            multiple
            v-model="states"
            :options="statesOption"
            :closeOnSelect="false"
            placeholder="Select"
          ></v-select>
        </div>
      </div>
      <!--Can't edit preferred states-->
      <div
        class="mb-4 form-element"
        v-if="manager_lock.includes('settings.preferences.preferred.states')"
      >
        <label class="col-form-label">Preferred</label>
        <div>
          <span
            class="mb-4"
            v-for="state in preferred_states"
            style="margin-right: 0.25em"
          >
            <base-button type="neutral">{{ state }}</base-button>
          </span>
        </div>
      </div>
      <!--Can edit preferred states-->
      <div class="mb-4 form-element" v-else>
        <label class="col-form-label">Preferred</label>
        <div class="form-element__input">
          <v-select
            multiple
            v-model="preferred_states"
            :options="preferredStatesOption"
            :closeOnSelect="false"
            placeholder="Select"
          ></v-select>
        </div>
      </div>
      <!--Can't edit number-->
      <div class="mb-4 form-element" v-if="manager_lock.includes('number')">
        <label class="col-form-label">Number</label>
        <div>
          <span>
            <base-button type="neutral">{{ formattedNumber }}</base-button>
          </span>
        </div>
      </div>
      <!--Can edit number-->
      <div class="form-element" v-else>
        <label class="col-form-label">Number</label>
        <div class="form-element__input">
          <input
            class="form-control"
            name="number"
            id="number"
            type="text"
            placeholder="555-123-4567"
            v-model="user.number"
          />
        </div>
      </div>
      <vue-cal
        ref="calendar"
        :disable-views="['years', 'year', 'month', 'day']"
        :time-from="6 * 60"
        :time-to="22 * 60"
        hideTitleBar
        hideViewSelector
        startWeekOnSunday
        :events="events"
        :timeCellHeight="58"
        :timeFormat="'hh:mm {am}'"
        :small="true"
        :time-step="60"
        :minEventWidth="98"
        :snapToTime="10"
        :editable-events="{
          title: false,
          drag: true,
          resize: true,
          delete: true,
          create: true,
        }"
      />
    </div>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/drag-and-drop";
import "vue-cal/dist/vuecal.css";
import { DateTime } from "luxon";
import { WEEK } from "../../constants/calendar";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "../../store/userStore";
import { STATES } from "../../constants/global";
import { useToast } from "vue-toastification";
import { globalStore } from "../../store/globalStore";

const toast = useToast();

export default {
  name: "Settings",
  components: { VueCal },
  data() {
    return {
      events: [
        // {
        //   start: "2022-06-22 12:00",
        //   end: "2022-06-22 14:00",
        // },
        // {
        //   start: "2022-06-22 16:00",
        //   end: "2022-06-22 19:00",
        // },
      ],
      vertical: [],
      states: [],
      preferred_states: [],
      STATES_CONST: STATES,
      manager_lock: [],
      isFetching: false,
    };
  },
  watch: {
    "user.number"() {
      this.user.number = this.formattedNumber;
    },
  },
  computed: {
    ...mapState(useUserStore, ["user"]),
    ...mapState(globalStore, ["verticals"]),
    verticalOptions() {
      return this.verticals.filter(
        (z) => !this.vertical?.find((i) => z.uuid === i.uuid)
      );
    },
    formattedNumber() {
      try {
        let number = this.user.number
          .replaceAll(/[^\d]/g, "")
          .replace("^1", "");
        return `(${number.substring(0, 3)}) ${number.substring(
          3,
          6
        )}-${number.substring(6, 10)}`;
      } catch (e) {
        return "No Number";
      }
    },
    statesOption() {
      return this.STATES_CONST.filter((z) => !this.states.includes(z));
    },
    preferredStatesOption() {
      return this.STATES_CONST.filter(
        (z) => !this.preferred_states.includes(z)
      );
    },
    week() {
      const dt = DateTime.now();
      const week = WEEK.map((day, index) => ({
        key: day,
        date: dt
          .startOf("week")
          .plus({ days: index - 1 })
          .toFormat("yyyy-MM-dd"),
      }));

      return week;
    },
  },
  async created() {
    await this.fetchVerticals();
    this.events = this.setUserEvents();
    this.states = this.setUserStates();
    this.preferred_states = this.setUserPreferredStates();
    this.vertical = this.setUserVerticals();
    this.manager_lock = this.user.manager_lock;
  },
  methods: {
    ...mapActions(useUserStore, ["saveUser"]),
    ...mapActions(globalStore, ["fetchVerticals"]),
    setUserEvents() {
      try {
        const days = this.user.settings.filters.hours;
        const events = [];
        for (let dayName in days) {
          days[dayName].map((duration) => {
            const date = this.week.find((z) => z.key === dayName).date;
            events.push({
              start: `${date} ${duration.open}`,
              end: `${date} ${duration.close}`,
            });
          });
        }
        return events;
      } catch (e) {
        console.error("error: mapping user events failed");
        return [];
      }
    },
    setUserStates() {
      try {
        return this.user.settings.filters.states;
      } catch (e) {
        console.error("error: mapping user states failed");
        return [];
      }
    },
    setUserPreferredStates() {
      try {
        if (this.user.settings.preferences?.preferred?.states) {
          return this.user.settings.preferences.preferred.states;
        } else {
          return [];
        }
      } catch (e) {
        console.error("error: mapping user states failed");
        return [];
      }
    },
    setUserVerticals() {
      try {
        return this.user.settings.verticals;
      } catch (e) {
        console.error("error: mapping user verticals failed");
        return [];
      }
    },
    async submitSchedule() {
      this.isFetching = true;
      try {
        const events = this.$refs.calendar.view.events;

        let updatedEvents = {};
        events.forEach((element) => {
          const date = new Date(element.start).format("YYYY-MM-DD");
          const day = this.week.find((z) => z.date === date).key;

          if (!updatedEvents[day]) {
            updatedEvents[day] = [];
          }
          updatedEvents[day].push({
            open: new Date(element.start).format("HH:mm"),
            close: new Date(element.end).format("HH:mm"),
          });
        });

        await this.saveUser({
          number: this.user.number,
          settings: {
            filters: {
              states: this.states,
              hours: { ...updatedEvents },
            },
            preferences: {
              preferred: {
                states: this.preferred_states,
              },
            },
            verticals: this.vertical.map((z) => ({
              uuid: z.uuid,
              name: z.name,
            })),
          },
        });
        toast.success("Data updated");
        this.isFetching = false;
      } catch (error) {
        this.isFetching = false;
        toast.error("Data saving error");
        console.log("error: mapping shchedule");
      }
    },
  },
};
</script>
