<template>
  <ManagerSettings v-if="user.roles.includes('MANAGER')" />
  <AgentSettings  v-if="user.roles.includes('AGENT')"  />
</template>

<script>
import { mapState } from "pinia";
import AgentSettings from "./AgentSettings.vue";
import ManagerSettings from "./ManagerSettings.vue";
import { useUserStore } from "../../store/userStore";
import { ROLES } from "../../constants/global";

export default {
  components: { AgentSettings, ManagerSettings },
  data() {
    return {
      ROLES,
    };
  },
  computed: {
    ...mapState(useUserStore, ["role", "user"]),
  },
};
</script>
