<template>
  <div>
    <h2 class="page-title">Overview</h2>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col" v-if="!user.roles?.includes('AGENT')">
          <stats-card
            title="LOGGED IN AGENTS"
            type="gradient-orange"
            :sub-title="String(user.summary.agents.length)"
            icon="ni ni-single-02"
          />
        </div>
        <div class="col">
          <stats-card
            title="ACTIVE CALLS"
            type="gradient-orange"
            :sub-title="String(user.summary.active)"
            icon="ni ni-check-bold"
          />
        </div>
        <div class="col">
          <stats-card
            title="CALLS  TODAY"
            type="gradient-orange"
            :sub-title="String(user.summary.calls)"
            icon="fa fa-phone"
          />
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col"  v-if="!user.roles?.includes('AGENT')">
          <agents-table title="Agents" :small="true" />
        </div>
        <div class="col">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Performance
                  </h6>
                  <h5 class="h3 mb-0">Total Calls</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas ref="chart" :height="350" :id="ordersChartID2"></canvas>
            </div>
          </card>
        </div>
      </div>
    </div>
    <recent-calls-table title="Recent Calls" :projects="history" />
    <agent-popup v-if="false" />
  </div>
</template>

<script>
import RecentCallsTable from "@/components/RecentCallsTable";
import AgentsTable from "@/components/AgentsTable";
import { useUserStore } from "../../store/userStore";
import { mapState, mapActions } from "pinia";
import AgentPopup from "../../components/Popups/AgentPopup.vue";
import {
  barChartStacked,
  ordersChart,
  pieChart,
} from "../../components/theme-components/Charts/Chart";
import { useReportsStore } from "@/store/reportsStore";

export default {
  name: "overview",
  components: {
    RecentCallsTable,
    AgentsTable,
    AgentPopup,
  },
  data() {
    return {
      salesChartID: "salesChart",
      ordersChartID2: "ordersChart2",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
    };
  },
  computed: {
    ...mapState(useUserStore, ["history", "user"]),
    ...mapState(useReportsStore, ["summary"]),
    callsChart() {
      if (!this.summary.interval) return [];
      return {
        labels: this.summary.interval.map((z) => z.interval),
        data: this.summary.interval.map((z) => z.records),
      };
    },
  },
  created() {
    this.fetchHistory();
  },
  mounted() {
    this.fetchSummary({
      start: new Date()
        .toISOString()
        .replace(/[0-9]{2}\-[0-9]{2}T.*$/, "01-01 00:00:00"),
      end: new Date().toISOString().replace(/T.*$/, " 23:59:59"),
      interval: "month",
    }).then(() => {
      if (this.$refs.chart) {
        this.callsChartModule = ordersChart(
          this.ordersChartID2,
          this.callsChart,
          "#6073E5"
        );
      }
    });
  },
  methods: {
    ...mapActions(useUserStore, ["fetchHistory"]),
    ...mapActions(useReportsStore, ["fetchSummary"]),
  },
};
</script>
