<template>
  <popup
    :title="
      dispositioningData.disposition || 'Disposition Required to Complete Call'
    "
    @close="closePopup"
    v-if="contact.call_id !== false"
  >
    <Form @submit="submit">
      <base-input
        :value="contact.name"
        class="mb-3"
        alternative
        name="name"
        addon-left-icon="ni ni-single-02"
        placeholder="Name"
      >
      </base-input>
      <base-input
        :value="contact.email"
        alternative
        class="mb-3"
        name="email"
        addon-left-icon="ni ni-email-83"
        placeholder="Email"
      >
      </base-input>
      <base-input
        :value="contact.address"
        class="mb-3"
        alternative
        name="address"
        addon-left-icon="ni ni-pin-3"
        placeholder="Address"
      >
      </base-input>
      <base-input
        :value="contact.city"
        class="mb-3"
        alternative
        name="city"
        addon-left-icon="ni ni-world"
        placeholder="City"
      >
      </base-input>
      <base-input
        :value="contact.state"
        class="mb-3"
        alternative
        name="state"
        placeholder="State"
        addon-left-icon="bi bi-geo-fill"
      >
      </base-input>
      <base-input
        :value="contact.zip"
        class="mb-3"
        alternative
        name="zip"
        addon-left-icon="bi bi-cursor-fill"
        placeholder="Zip"
      >
      </base-input>
      <!--disposition dropdown-->
      <div
        class="form-group mb-3"
        v-if="dispositioningData?.options?.dispositions?.good?.length > 0"
      >
        <div class="input-group input-group-alternative">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><i class="ni ni-satisfied"></i
            ></span>
          </div>
          <select class="form-control" v-model="disposition">
            <optgroup label="Positive Dispositions">
              <option
                v-for="dispo in dispositioningData.options.dispositions.good"
              >
                {{ dispo }}
              </option>
            </optgroup>
            <optgroup label="Negative Dispositions">
              <option
                v-for="dispo in dispositioningData.options.dispositions.bad"
              >
                {{ dispo }}
              </option>
            </optgroup>
            <optgroup label="Other Dispositions">
              <option
                v-for="dispo in dispositioningData.options.dispositions.other"
              >
                {{ dispo }}
              </option>
            </optgroup>
          </select>
          <!---->
        </div>
      </div>
      <!--end disposition dropdown-->
      <base-input
        :value="contact.more.carrier_interest"
        class="mb-3"
        alternative
        name="carrier_interest"
        addon-left-icon="ni ni-briefcase-24"
        placeholder="Carrier Interest"
      >
      </base-input>
      <!--disposition icons-->
      <div class="bottom-row">
        <dropdown-buttons @change="dispositionHandler" />
        <button :disabled="isFetching" type="submit" class="btn btn-info">
          Submit
        </button>
      </div>
      <!--end disposition icons-->
    </Form>
  </popup>
</template>

<script>
import Popup from "./Popup.vue";
import { Form } from "vee-validate";
import DropdownButtons from "../DropdownButtons.vue";
import { mapActions, mapState } from "pinia";
import { websocketStore } from "@/store/websocketStore";
import { STATUSES } from "@/constants/global";
import { axiosService } from "@/services/baseService";
import { useToast } from "vue-toastification";
import { useUserStore } from "@/store/userStore";

const toast = useToast();

export default {
  components: { Popup, DropdownButtons, Form },
  data() {
    return {
      dispositions: {
        bad: ["Do not call", "Wrong number"],
        good: ["Enrolled", "Made Appointment"],
        other: ["Disconnected"],
      },
      contact: { call_id: false },
      disposition: null,
      isFetching: false,
    };
  },
  computed: {
    ...mapState(websocketStore, ["status", "callId"]),
    ...mapState(useUserStore, ["dispositioningData"]),
  },
  watch: {
    dispositioningData() {
      this.contact = this.dispositioningData.contact;
      this.disposition = this.dispositioningData.disposition;
    },
  },
  mounted() {
    document.body.classList.add("overflow");
  },
  beforeUnmount() {
    document.body.classList.remove("overflow");
  },
  methods: {
    ...mapActions(websocketStore, ["setPopup", "setStatus"]),
    ...mapActions(useUserStore, ["fetchDispositioningData", "fetchHistory"]),
    async closePopup() {
      this.setPopup({ name: "disposition", show: false });
      /*if (this.status === STATUSES.disposition) {
        await this.setStatus(STATUSES.connected);
      }*/
      try {
        let user = await axiosService.get(
          `api/user/${useUserStore().baseUser.uuid}`
        );
        console.log({
          status: user.data.status,
          match: user.data.status === "disposition",
        });
        //await this.setStatus("disposition");
        //window.location.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async submit(values) {
      console.log({
        values: values,
      });
      let contactKeys = Object.keys(this.contact);
      let more = this.contact.more;
      Object.keys(values).forEach((key) => {
        if (!contactKeys.includes(key)) {
          more[key] = values[key];
        }
      });
      this.isFetching = true;
      const handler = () => {
        toast.error("Data saving error");
        console.log("error: submit disposition popup");
        this.isFetching = false;
      };
      await axiosService
        .patch(`api/disposition/${this.callId}`, {
          contact: {
            ...values,
            more: more,
          },
          disposition: this.disposition,
        })
        .then(() => {
          this.fetchDispositioningData();
          this.closePopup();
          toast.success("Data updated");
          this.fetchHistory();
          this.isFetching = false;
        }, handler);
    },
    dispositionHandler(value) {
      this.disposition = value;
    },
  },
};
</script>
