export const CHART_COLORS = [
  {
    className: "bg-danger",
    color: "#DD4B39",
  },
  {
    className: "bg-primary",
    color: "#1DA1F2",
  },
  {
    className: "bg-green",
    color: "#30D987",
  },
  {
    className: "bg-yellow",
    color: "#F5C342",
  },
  {
    className: "bg-orange",
    color: "#F56636",
  },
  {
    className: "bg-info",
    color: "#5E72E4",
  },
  {
    className: "bg-danger",
    color: "#DD4B39",
  },
  {
    className: "bg-primary",
    color: "#1DA1F2",
  },
  {
    className: "bg-green",
    color: "#30D987",
  },
  {
    className: "bg-yellow",
    color: "#F5C342",
  },
  {
    className: "bg-orange",
    color: "#F56636",
  },
  {
    className: "bg-info",
    color: "#5E72E4",
  },
  {
    className: "bg-danger",
    color: "#DD4B39",
  },
  {
    className: "bg-primary",
    color: "#1DA1F2",
  },
  {
    className: "bg-green",
    color: "#30D987",
  },
  {
    className: "bg-yellow",
    color: "#F5C342",
  },
  {
    className: "bg-orange",
    color: "#F56636",
  },
  {
    className: "bg-info",
    color: "#5E72E4",
  },
  {
    className: "bg-danger",
    color: "#DD4B39",
  },
  {
    className: "bg-primary",
    color: "#1DA1F2",
  },
  {
    className: "bg-green",
    color: "#30D987",
  },
  {
    className: "bg-yellow",
    color: "#F5C342",
  },
  {
    className: "bg-orange",
    color: "#F56636",
  },
  {
    className: "bg-info",
    color: "#5E72E4",
  },
];
