<template>
  <div>
    <div class="d-flex align-items-start justify-content-between mb-4">
      <h2 class="page-title mb-0">Agents</h2>
      <button class="btn btn-primary" @click="showPopupHandler">
        Add Agent
      </button>
    </div>
    <div class="container-fluid p-0">
      <agents-table title="Agents" />
    </div>
    <add-agent-popup v-if="showPopup" @close="showPopupHandler" />
    <remove-agent-popup v-if="false" />
  </div>
</template>

<script>
import AgentsTable from "@/components/AgentsTable";
import AddAgentPopup from "../components/Popups/AddAgentPopup.vue";
import RemoveAgentPopup from "../components/Popups/RemoveAgentPopup.vue";

export default {
  name: "overview",
  components: {
    AgentsTable,
    AddAgentPopup,
    RemoveAgentPopup,
  },
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    showPopupHandler() {
      this.showPopup = !this.showPopup;
    },
  },
};
</script>
