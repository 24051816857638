<template>
  <div class="status-bar__funds">
    <div class="status-bar__funds--value">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        @click="toggleStatus"
      >
        Toggle Status
      </button>
    </div>
  </div>
</template>
<script>
import { useUserStore } from "@/store/userStore";
import { mapActions, mapState } from "pinia";
import { websocketStore } from "@/store/websocketStore";

export default {
  name: "current-funds",
  computed: {
    ...mapState(websocketStore, ["status"]),
  },
  methods: {
    ...mapActions(useUserStore, ["saveUser"]),
    ...mapActions(websocketStore, ["setStatus"]),
    async toggleStatus() {
      if (this.status === "ready") {
        await this.saveUser({
          status: "paused",
        });
        await this.setStatus("paused");
      } else if (this.status === "paused") {
        await this.saveUser({
          status: "ready",
        });
        await this.setStatus("ready");
      }
    },
  },
};
</script>
