<template>
  <popup
    v-if="typeof agent !== 'undefined'"
    :title="agent.name"
    @close="closePopup"
    class="edit-agent-popup"
  >
    <hr />
    <Form @submit="submit" v-if="agent && agent.hasOwnProperty('number')">
      <div class="form-group mb-3">
        <label
          class="form-control-label"
          v-on:click="manager_lock('settings.verticals')"
        >
          <i
            class="bi-lock"
            v-if="agent.manager_lock?.includes('settings.verticals')"
          ></i>
          <i class="bi-unlock" v-else></i>
          Vertical
        </label>
        <v-select
          multiple
          v-model="agent.settings.verticals"
          :options="verticalOptions"
          label="name"
          value="uuid"
          :closeOnSelect="false"
          placeholder="Select"
        ></v-select>
      </div>
      <div class="form-group mb-3">
        <label
          class="form-control-label"
          v-on:click="manager_lock('settings.filters.states')"
        >
          <i
            class="bi-lock"
            v-if="agent.manager_lock?.includes('settings.filters.states')"
          ></i>
          <i class="bi-unlock" v-else></i>
          Accepted States
        </label>
        <v-select
          multiple
          v-model="agent.settings.filters.states"
          :options="statesOption"
          :closeOnSelect="false"
          placeholder="Select"
        ></v-select>
      </div>
      <div
        class="form-group mb-3"
        v-if="agent.settings.preferences?.preferred?.states"
      >
        <label
          class="form-control-label"
          v-on:click="manager_lock('settings.preferences.preferred.states')"
        >
          <i
            class="bi-lock"
            v-if="
              agent.manager_lock?.includes(
                'settings.preferences.preferred.states'
              )
            "
          ></i>
          <i class="bi-unlock" v-else></i>
          Preferred States
        </label>
        <v-select
          multiple
          v-model="agent.settings.preferences.preferred.states"
          :options="preferredStatesOption"
          :closeOnSelect="false"
          placeholder="Select"
        ></v-select>
      </div>
      <div class="form-group mb-3" v-if="number !== false">
        <!--phone number with unlock icon-->
        <label class="form-control-label" v-on:click="manager_lock('number')">
          <i class="bi-lock" v-if="agent.manager_lock?.includes('number')"></i>
          <i class="bi-unlock" v-else></i>
          Phone Number
        </label>
        <base-input
          :value="agent.number"
          @input="(e) => (agent.number = e.target.value)"
          class="mb-3"
          alternative
          name="number"
          placeholder="Number"
        ></base-input>
      </div>
      <!-- Daily Cap based on settings.cap.daily -->
      <div class="form-group mb-3" v-if="agent.settings.caps !== false">
        <!--phone number with unlock icon-->
        <label class="form-control-label" v-on:click="manager_lock('settings.caps')">
          <i class="bi-lock" v-if="agent.manager_lock?.includes('settings.caps')"></i>
          <i class="bi-unlock" v-else></i>
          Daily Cap
        </label>
        <base-input
          :value="agent.settings.caps.daily"
          @input="(e) => (agent.settings.caps.daily = e.target.value)"
          class="mb-3"
          alternative
          name="daily_cap"
          placeholder="DailyCap"
        ></base-input>
      </div>
      <!-- Add option to lock agent -->
      <div class="form-group mb-3">
        <div class="form-check form-switch ps-0">
          <input
            class="form-check-input ms-0"
            type="checkbox"
            id="flexSwitchCheckDefault"
            v-on:click="manager_lock('status')"
            :checked="agent.manager_lock?.includes('status')"
          />
          <label
            class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
            for="flexSwitchCheckDefault"
          >
            Pause Agent
          </label>
        </div>
      </div>
      <div class="bottom-row">
        <button
          type="button"
          class="btn btn-secondary"
          @click.prevent="closePopup"
        >
          Cancel
        </button>
        <button :disabled="isFetching" type="submit" class="btn btn-primary">
          Save
        </button>
      </div>
    </Form>
  </popup>
</template>

<script>
import Popup from "./Popup.vue";
import { Form } from "vee-validate";
// import { axiosService } from "@/services/baseService";
import { useToast } from "vue-toastification";
import { STATES } from "@/constants/global";
import { mapActions, mapState } from "pinia";
import { globalStore } from "@/store/globalStore";
import { axiosService } from "@/services/baseService";
import { useUserStore } from "@/store/userStore";

const toast = useToast();

export default {
  components: { Popup, Form },
  props: { agent: { manager_lock: [] } },
  data() {
    return {
      isFetching: false,
      vertical: [],
      states: [],
      STATES_CONST: STATES,
      selectedAgent: {},
      number: false,
    };
  },
  mounted() {
    document.body.classList.add("overflow");
  },
  beforeUnmount() {
    document.body.classList.remove("overflow");
  },
  watch: {},
  methods: {
    ...mapActions(globalStore, ["fetchVerticals"]),
    ...mapActions(useUserStore, ["saveUser"]),
    closePopup() {
      this.$emit("close");
    },
    manager_lock(field) {
      if (this.agent.manager_lock.includes(field)) {
        this.agent.manager_lock = this.agent.manager_lock.filter(
          (item) => item !== field
        );
      } else {
        this.agent.manager_lock.push(field);
      }
    },
    async submit() {
      let payload = {
        manager_lock: this.agent.manager_lock,
        settings: this.agent.settings,
        number: this.agent.number,
      };
      if (this.agent.manager_lock.includes("status")) {
        payload.status = "paused";
      }
      await axiosService
        .patch(`api/user/${this.agent.uuid}`, payload)
        .then(() => {
          this.closePopup();
          toast.success("Agent Updated");
          this.isFetching = false;
        });
    },
    setUserVerticals() {
      try {
        return this.agent.settings.verticals;
      } catch (e) {
        console.error("error: mapping user verticals failed");
        return [];
      }
    },
  },
  async created() {
    await this.fetchVerticals();
    this.vertical = this.setUserVerticals();
    this.selectedAgent = this.agent;
    if (
      !this.selectedAgent.settings.preferences?.preferred?.states.length > 0
    ) {
      this.selectedAgent.settings.preferences = {
        preferred: {
          states: [],
        },
      };
    }
    this.number = this.agent.number;
  },
  computed: {
    ...mapState(globalStore, ["verticals"]),
    verticalOptions() {
      return this.verticals.filter(
        (z) =>
          !this.selectedAgent?.settings?.verticals?.find(
            (i) => z.uuid === i.uuid
          )
      );
    },
    statesOption() {
      return this.STATES_CONST.filter(
        (z) => !this.selectedAgent?.settings?.filters?.states?.includes(z)
      );
    },
    preferredStatesOption() {
      return this.STATES_CONST.filter(
        (z) =>
          !this.selectedAgent?.settings?.preferences?.preferred?.states?.includes(
            z
          )
      );
    },
  },
};
</script>
