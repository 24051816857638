import { defineStore } from "pinia";
import { STATUSES } from "../constants/global";
import { useUserStore } from "./userStore";

export const websocketStore = defineStore("websocket", {
  state: () => {
    return {
      connected: false,
      status: STATUSES.inactive,
      callId: null,
      user: null,
      popups: {
        disposition: false,
      },
      popup: false,
    };
  },
  actions: {
    setConnection(connected) {
      this.connected = connected;
    },
    setStatus(status) {
      const store = useUserStore();
      if (status === STATUSES.ready) {
        store.fetchHistory();
      }
      this.status = status;
      if (status === STATUSES.disposition) {
        this.setPopup({ name: "disposition", show: true });
      }
    },
    setCallId(callId) {
      this.callId = callId;
    },
    setUser(user) {
      this.user = user;
    },
    setPopup({ name, show }) {
      this.popups[name] = show;
    },
  },
});
