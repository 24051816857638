<template>
  <div class="content">
    <!-- your content here -->
    <side-bar :logo="require('../assets/images/Ring2Media-logo-2.png')">
      <template v-slot:links-after>
        <hr class="my-4" />
        <!-- <h6 class="navbar-heading p-0 text-teal">MAIN MENU</h6> -->

        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Overview' }">
              <span class="nav-link__icon">
                <img
                  src="../assets/images/sidebar-icons/overview.svg"
                  alt="Overview"
                />
              </span>
              <span class="nav-link-text">Overview</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'History' }">
              <span class="nav-link__icon">
                <img
                  src="../assets/images/sidebar-icons/calendar.svg"
                  alt="History"
                />
              </span>
              <span class="nav-link-text">History</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Reports' }">
              <span class="nav-link__icon">
                <img
                  src="../assets/images/sidebar-icons/reports.svg"
                  alt="Reports"
                />
              </span>
              <span class="nav-link-text">Reports</span>
            </router-link>
          </li>
          <li v-if="user?.roles?.includes('MANAGER') && !user?.roles?.includes('AGENT')" class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Agents' }">
              <span class="nav-link__icon">
                <img
                  src="../assets/images/sidebar-icons/agents.svg"
                  alt="Reports"
                />
              </span>
              <span class="nav-link-text">Agents</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Settings' }">
              <span class="nav-link__icon">
                <img
                  src="../assets/images/sidebar-icons/settings.svg"
                  alt="Settings"
                />
              </span>
              <span class="nav-link-text">Settings</span>
            </router-link>
          </li>
        </ul>
      </template>
      <template v-slot:help-center>
        <help-center />
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar type="light"></dashboard-navbar>
      <div class="page-content">
        <router-view v-if="user"></router-view>
        <div @click="$sidebar.displaySidebar(false)"></div>
      </div>
    </div>
    <Transition>
      <caller-popup v-if="status === 'disposition' && popups.disposition" />
      <!-- <caller-popup /> -->
    </Transition>
  </div>
</template>

<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import HelpCenter from "../components/SidebarPlugin/HelpCenter.vue";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "../store/userStore";
import { websocketStore } from "../store/websocketStore";
import axios from "axios";
import { io } from "socket.io-client";
import CallerPopup from "@/components/Popups/CallerPopup.vue";
import { ROLES } from "../constants/global";
import { usePaymentsStore } from "../store/paymentsStore";
import { axiosService } from "@/services/baseService";
import { addLocalStorageItem } from "@/helpers/util";
import router from "@/router";

let socket = null;
let disconnect_count = 0;

export default {
  components: {
    DashboardNavbar,
    HelpCenter,
    CallerPopup,
  },
  data() {
    return {
      ROLES,
    };
  },
  computed: {
    ...mapState(websocketStore, ["popups", "status"]),
    ...mapState(useUserStore, ["user", "role"]),
  },
  async created() {
    axios.defaults.headers.common["X-Access-Token"] = localStorage.getItem(
      "token"
    );
    await this.fetchUser();
    this.getPayments();

    socket = io("https://api.voxba.ring2media.com");
    //socket = io("http://localhost:3000");

    socket.on("connect", () => {
      if (disconnect_count > 0) {
        let text;
        if (confirm("New Version Available. Press OK to refresh") === true) {
          window.location.reload();
        } else {
          alert("Please refresh the page to get the latest version");
        }
      }
      console.log("socket connected:", socket.connected);
      this.setConnection(socket.connected);

      socket.emit("token", localStorage.getItem("token"));

      socket.on("user", (response) => {
        try {
          this.setUser(JSON.parse(response));
        } catch (e) {
          this.setUser(response);
        }
      });
      socket.on("disposition", (response) => {
        this.setStatus("disposition");
        this.setCallId(response);
        this.fetchDispositioningData();
      });
      socket.on("status", (status) => {
        /*switch (response) {
          case "ready":
            this.setStatus("ready");
            break;
          case "connected":
            this.setStatus("connected");
        }*/
        console.log({
          status: status
        });
        this.setStatus(status);
      });
      socket.on("call_id", (call_id) => {
        this.setCallId(call_id);
        this.fetchDispositioningData();
      });
      // refreshToken handling
      setInterval(async () => {
        let accessToken = localStorage.getItem("token");
        if (accessToken) {
          console.log("refreshing token");
          console.log(accessToken);
          socket.emit("refreshToken", accessToken);
        }
      }, 1000 * 60 * 5);
    });
    socket.on("accessToken", (data) => {
      console.log({
        accessToken: data.accessToken,
        data: data,
      });
      if (data.accessToken !== "undefined") {
        localStorage.setItem("token", data.accessToken);
        socket.emit("token", data.accessToken);
      }
    });
    socket.on("refresh", (data) => {
      console.log("refreshing");
      socket.emit("token", localStorage.getItem("token"));
    });
    socket.on("disconnect", () => {
      this.setStatus("offline");
      disconnect_count++;
      console.log("socket connected:", socket.connected);
      this.setConnection(socket.connected);
    });
  },
  beforeUnmount() {
    socket.disconnect();
    disconnect_count = 0;
  },
  methods: {
    ...mapActions(useUserStore, ["fetchUser", "fetchDispositioningData"]),
    ...mapActions(websocketStore, [
      "setConnection",
      "setStatus",
      "setUser",
      "setCallId",
    ]),
    ...mapActions(usePaymentsStore, ["getPayments"]),
  },
};
</script>
<style></style>
