<template>
  <div>
    <h2 class="page-title">Overview</h2>
    <second-overview-header :status="status" />
    <recent-calls-table
      v-if="
        [STATUSES.inactive, STATUSES.paused, STATUSES.ready].includes(status) &&
        !user.roles?.includes('MANAGER')
      "
      title="Recent Calls"
      :projects="history"
    />
    <active-call
      v-else-if="[STATUSES.connected, STATUSES.disposition].includes(status)"
    />
  </div>
</template>

<script>
import SecondOverviewHeader from "@/components/SecondOverviewHeader";
import RecentCallsTable from "@/components/RecentCallsTable";
import ActiveCall from "@/components/ActiveCallTable";
import { useUserStore } from "@/store/userStore";
import { mapState, mapActions } from "pinia";
import { websocketStore } from "@/store/websocketStore";
import { STATUSES } from "@/constants/global";

export default {
  name: "overview",
  components: {
    SecondOverviewHeader,
    RecentCallsTable,
    ActiveCall,
  },
  data() {
    return {
      STATUSES,
    };
  },
  computed: {
    ...mapState(useUserStore, ["role", "history", "user"]),
    ...mapState(websocketStore, ["status"]),
  },
  created() {
    this.fetchHistory();
  },
  methods: {
    ...mapActions(useUserStore, ["fetchHistory"]),
  },
};
</script>
