import { defineStore } from "pinia";
import { axiosService } from "@/services/baseService";
import { generateCSV } from "../helpers/generateCSV";

export const useReportsStore = defineStore("reports", {
  state: () => {
    return {
      summary: null,
      reports: {
        calls: null,
        contacts: null,
      },
      reportsPagination: {
        calls: {
          limit: 8,
          offset: 0,
          page: 1,
          count: 0,
          pagesCount: 0,
          search: "",
        },
        contacts: {
          limit: 8,
          offset: 0,
          page: 1,
          count: 0,
          pagesCount: 0,
          search: "",
        },
      },
      promises: {
        reportsCalls: {
          fetching: false,
        },
        reportsContacts: {
          fetching: false,
        },
        summary: {
          fetching: false,
        },
      },
    };
  },
  actions: {
    async fetchReportsCalls(page, search = "") {
      // if (page === this.reportsPagination.calls.page && !search) return;

      try {
        this.promises.reportsCalls = { fetching: true };
        if (search) page = 1;
        if (page) {
          this.reportsPagination.calls = {
            ...this.reportsPagination.calls,
            offset: (page - 1) * this.reportsPagination.calls.limit,
            page,
            search,
          };
        }
        let query = `?limit=${this.reportsPagination.calls.limit}&offset=${this.reportsPagination.calls.offset}`;
        if (this.reportsPagination.calls.search) {
          query = `${query}&q=${this.reportsPagination.calls.search}`;
        }
        const resp = await axiosService.get(`api/report/calls${query}`, {
          auth: true,
        });
        this.reports.calls = resp.data.data;
        this.reportsPagination.calls.count = resp.data.meta.records;
        this.reportsPagination.calls.pagesCount = Math.ceil(
          resp.data.meta.records / this.reportsPagination.calls.limit
        );
        this.promises.reportsCalls = { fetching: false };
      } catch (e) {
        this.promises.reportsCalls = { fetching: false };
        throw Error(e);
      }
    },
    async fetchReportsCallsCSV() {
      try {
        const query = `?limit=99999&offset=0&format=csv`;
        const resp = await axiosService.get(`api/report/calls${query}`, {
          auth: true,
        });
        generateCSV(resp.data, "calls-report");
      } catch (e) {
        throw Error(e);
      }
    },
    async fetchReportsContacts(page, search = "") {
      // if (page === this.reportsPagination.contacts.page && !search) return;

      try {
        this.promises.reportsContacts = { fetching: true };
        if (search) page = 1;
        if (page) {
          this.reportsPagination.contacts = {
            ...this.reportsPagination.contacts,
            offset: (page - 1) * this.reportsPagination.contacts.limit,
            page,
            search,
          };
        }
        let query = `?limit=${this.reportsPagination.contacts.limit}&offset=${this.reportsPagination.contacts.offset}`;
        if (this.reportsPagination.contacts.search) {
          query = `${query}&q=${this.reportsPagination.contacts.search}`;
        }
        const resp = await axiosService.get(`api/report/contacts${query}`, {
          auth: true,
        });
        this.reports.contacts = resp.data.data;
        this.reportsPagination.contacts.count = resp.data.meta.records;
        this.reportsPagination.contacts.pagesCount = Math.ceil(
          resp.data.meta.records / this.reportsPagination.contacts.limit
        );
        this.promises.reportsContacts = { fetching: false };
      } catch (e) {
        this.promises.reportsContacts = { fetching: false };
        throw Error(e);
      }
    },
    async fetchReportsContactsCSV() {
      try {
        const query = `?limit=99999&offset=0&format=csv`;
        const resp = await axiosService.get(`api/report/contacts${query}`, {
          auth: true,
        });
        generateCSV(resp.data, "contacts-report");
      } catch (e) {
        throw Error(e);
      }
    },
    /**
     *
     * @param {number} start - date format yyyy-mm-dd
     * @param {number} end - date format yyyy-mm-dd
     * @param {number} interval - hour|day|week|month|year
     */
    async fetchSummary({ start, end, interval }) {
      try {
        const query = `?start=${start}&end=${end}&interval=${interval}`;
        const resp = await axiosService.get(`api/report/summary${query}`, {
          auth: true,
        });
        this.summary = resp.data;
      } catch (e) {
        this.promises.summary = { fetching: false };
        throw Error(e);
      }
    },
  },
});
