/* eslint-disable prettier/prettier */

export function createService(service) {
  const get = (
    URL,
    configWrapper = { auth: false, config: false, authKey: null }
  ) => {
    configWrapper.auth && addAuthHeader(configWrapper);
    if (configWrapper.config)
      return service.get(`/${URL}`, configWrapper.config);
    return service.get(`/${URL}`);
  };

  const post = (
    URL,
    payload,
    configWrapper = { auth: false, config: false, authKey: null }
  ) => {
    configWrapper.auth && addAuthHeader(configWrapper);
    if (configWrapper.config)
      return service.post(`/${URL}`, payload, configWrapper.config);
    return service.post(`/${URL}`, payload);
  };

  const patch = (
    URL,
    payload,
    configWrapper = { auth: false, config: false, authKey: null }
  ) => {
    configWrapper.auth && addAuthHeader(configWrapper);
    if (configWrapper.config)
      return service.patch(`/${URL}`, payload, configWrapper.config);
    return service.patch(`/${URL}`, payload);
  };
  const put = (
    URL,
    payload,
    configWrapper = { auth: false, config: false, authKey: null }
  ) => {
    configWrapper.auth && addAuthHeader(configWrapper);
    if (configWrapper.config)
      return service.put(`/${URL}`, payload, configWrapper.config);
    return service.put(`/${URL}`, payload);
  };

  const deleteRequest = (
    URL,
    configWrapper = { auth: false, config: false, authKey: null }
  ) => {
    configWrapper.auth && addAuthHeader(configWrapper);
    return service.delete(`/${URL}`);
  };
  const addAuthHeader = ({ authKey = "token" }) => {
    service.defaults.headers.common["X-Access-Token"] =
      localStorage.getItem(authKey) || "token";
  };

  return {
    get,
    post,
    patch,
    put,
    deleteRequest,
  };
}
