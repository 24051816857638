<template>
  <div class="add-disposition-settings">
    <div class="mb-5">
      <div class="form-group">
        <label class="form-control-label">Positive Outcomes</label>
        <div
          class="clearable-input"
          v-for="(item, index) in good"
          :key="`${index}-${item.uuid}`"
        >
          <i class="ni ni-fat-remove" @click="removeItem('good', item)"></i>
          <input
            v-model="item.value"
            class="form-control mb-3"
            type="text"
            placeholder="value"
          />
        </div>
      </div>
      <button
        type="button"
        class="btn btn-secondary mt-2"
        @click="addItem('good')"
      >
        <i class="ni ni-fat-add"></i>Add New Option
      </button>
    </div>
    <div class="mb-5">
      <div class="form-group">
        <label class="form-control-label">Negative Outcomes</label>
        <div
          v-for="(item, index) in bad"
          :key="`${index}-${item.uuid}`"
          class="clearable-input"
        >
          <i class="ni ni-fat-remove" @click="removeItem('bad', item)"></i>
          <input
            v-model="item.value"
            class="form-control mb-3"
            type="text"
            placeholder="value"
          />
        </div>
      </div>
      <button
        type="button"
        class="btn btn-secondary mt-2"
        @click="addItem('bad')"
      >
        <i class="ni ni-fat-add"></i>Add New Option
      </button>
    </div>
    <div>
      <div class="form-group">
        <label class="form-control-label">Other Outcomes</label>
        <div
          v-for="(item, index) in other"
          :key="`${index}-${item.uuid}`"
          class="clearable-input"
        >
          <i class="ni ni-fat-remove" @click="removeItem('other', item)"></i>
          <input
            v-model="item.value"
            class="form-control mb-3"
            type="text"
            placeholder="value"
          />
        </div>
      </div>
      <button
        type="button"
        class="btn btn-secondary mt-2"
        @click="addItem('other')"
      >
        <i class="ni ni-fat-add"></i>Add New Option
      </button>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    dispositions: {
      type: Object,
      default() {
        return {
          good: [],
          bad: [],
          other: [],
        };
      },
    },
  },
  data() {
    return {
      good:
        this.dispositions?.good?.map((z) => ({
          uuid: uuidv4(),
          value: z,
        })) || [],
      bad:
        this.dispositions?.bad?.map((z) => ({
          uuid: uuidv4(),
          value: z,
        })) || [],
      other:
        this.dispositions?.other?.map((z) => ({
          uuid: uuidv4(),
          value: z,
        })) || [],
    };
  },
  methods: {
    addItem(name) {
      this[name].push({
        uuid: uuidv4(),
        value: "",
      });
    },
    removeItem(name, item) {
      console.log(this[name]);
      const index = this[name].findIndex((z) => {
        return item.uuid === z.uuid;
      });
      this[name].splice(index, 1);
    },
  },
};
</script>
