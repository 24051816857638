<template>
  <edit-agent-popup
    v-if="showEditPopup && Object.hasOwn(selectedAgent, 'uuid')"
    @close="showEditPopupHandler({})"
    :agent="selectedAgent"
  />
  <div class="card">
    <div class="border-0 card-header d-flex justify-content-between">
      <h3 class="mb-0">{{ title }}</h3>

      <template v-if="!small">
        <div class="table-search d-flex">
          <label class="col-form-label">Search Agent</label>
          <base-input
            type="text"
            name="agent-search"
            placeholder="Search..."
            class="mb-0 mr-4"
            addon-left-icon="fas fa-search"
            v-model="searchQuery"
            @input="(e) => (searchQuery = e.target.value)"
          />
        </div>
      </template>
    </div>

    <div class="position-relative">
      <Preloader v-if="promises.agents.fetching"></Preloader>
      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        :data="agents"
        :defaultExpandAll="true"
      >
        <el-table-column label="Agent Name" prop="name" min-width="120px">
        </el-table-column>
        <el-table-column label="Email Address" prop="email" min-width="150px">
        </el-table-column>

        <el-table-column
          label="Verticals"
          min-width="100px"
          prop="status"
          className="wrap"
        >
          <template v-slot="{ row }">
            {{ row.settings?.verticals?.map((z) => z.name).join(", ") }}
          </template>
        </el-table-column>
        <el-table-column
          label="Accepted States"
          min-width="100px"
          prop="status"
          className="wrap"
        >
          <template v-slot="{ row }">
            {{ row.settings?.filters?.states?.join(", ") }}
          </template>
        </el-table-column>
        <el-table-column
          label="-"
          min-width="100px"
          prop="status"
          className="wrap"
        >
          <template v-slot="{ row }">
            <button class="btn btn-primary" @click="showEditPopupHandler(row)">
              Edit
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <base-pagination
      v-if="agentsPagination.pagesCount > 1"
      v-model="currentPage"
      :page-count="agentsPagination.pagesCount"
      first-number
      last-number
      :class="{ fetching: promises.agents.fetching }"
      @update:modelValue="paginationHandler"
    ></base-pagination>
  </div>
</template>

<script>
// import Badge from "@/components/Badge.vue";
import { DateTime } from "luxon";
import {
  ElTable,
  ElTableColumn,
  ElDropdown,
  ElDropdownMenu,
} from "element-plus";
import { mapActions, mapState } from "pinia";
import { useAgentsStore } from "@/store/agentsStore";
import { useUserStore } from "@/store/userStore";
import Preloader from "./Preloader.vue";
import { STATES } from "@/constants/global";
import EditAgentPopup from "../components/Popups/EditAgentPopup";

export default {
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Preloader,
    EditAgentPopup,
  },
  props: {
    itemLimit: {
      type: Number,
      default: 5,
    },
    title: {
      type: String,
      default: "Recent Calls",
    },
    headerLink: {
      type: Object,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      agent: null,
      currentPage: 1,
      STATES_CONST: STATES,
      showEditPopup: false,
      selectedAgent: {},
      timer: null,
      searchQuery: "",
    };
  },
  computed: {
    ...mapState(useAgentsStore, ["agents", "promises", "agentsPagination"]),
    ...mapState(useUserStore, ["user"]),
  },
  created() {
    this.currentPage = this.agentsPagination.page;
    this.fetchAgents();
  },
  methods: {
    ...mapActions(useAgentsStore, ["fetchAgents"]),
    duration(call_start, call_end) {
      if (!call_start || !call_end) return "-";

      const start = DateTime.fromISO(call_start);
      const end = DateTime.fromISO(call_end);
      const diff = end.diff(start, ["hours", "minutes", "seconds"]).toObject();
      const parseTime = (val) =>
        val < 10 ? `0${Math.ceil(val)}` : Math.ceil(val);
      return `${parseTime(diff.hours)}:${parseTime(diff.minutes)}:${parseTime(
        diff.seconds
      )}`;
    },
    paginationHandler(page) {
      this.fetchAgents(page, this.searchQuery);
    },
    statesOption(row) {
      return this.STATES_CONST.filter((z) => {
        if (row && Object.hasOwn(row, "settings")) {
          return !row.settings.filters.states.includes(z);
        } else {
          return true;
        }
      });
    },
    showEditPopupHandler(agent) {
      this.selectedAgent = agent;
      if (Object.hasOwn(agent, "uuid")) {
        this.showEditPopup = true;
      } else {
        this.showEditPopup = false;
      }
      //this.showEditPopup = !this.showEditPopup;
    },
    searchHandler() {
      this.fetchAgents(1, this.searchQuery);
    },
  },
  watch: {
    searchQuery: {
      handler() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.fetchAgents(1, this.searchQuery);
        }, 500);
      },
      immediate: true,
    },
  },
};
</script>
