import { defineStore } from "pinia";
import { axiosService } from "@/services/baseService";
import { useUserStore } from "./userStore";

export const useAgentsStore = defineStore("agents", {
  state: () => {
    return {
      agents: [],
      agentsPagination: {
        limit: 8,
        offset: 0,
        page: 1,
        count: 0,
        pagesCount: 0,
      },
      promises: {
        agents: {
          fetching: false,
        },
      },
    };
  },
  actions: {
    async fetchAgents(page, searchQuery = "") {
      //if (page === this.agentsPagination.page) return;

      try {
        const store = useUserStore();
        this.promises.agents = { fetching: true };
        if (page) {
          this.agentsPagination = {
            ...this.agentsPagination,
            offset: (page - 1) * this.agentsPagination.limit,
            page,
          };
        }
        const query = `?limit=${this.agentsPagination.limit}&offset=${this.agentsPagination.offset}&q=${searchQuery}`;
        const resp = await axiosService.get(
          `api/user/${store.baseUser.uuid}/agents${query}`,
          {
            auth: true,
          }
        );
        this.agents = resp.data.data.map((agent) => {
          //frontend fixes
          if (!agent.settings) {
            agent.settings = {
              filters: {
                states: [],
              },
              verticals: [],
              caps: {
                daily: 0,
              },
              preferences: { preferred: { states: [] } },
            };
          } else if (!agent.settings.filters) {
            agent.settings.filters = {
              states: [],
            };
          } else if (!agent.settings.filters.states) {
            agent.settings.filters.states = [];
          }
          if (!agent.settings.hasOwnProperty("caps")) {
            agent.settings.caps = {
              daily: 0,
            };
          }
          return agent;
        });
        this.agentsPagination.count = resp.data.meta.records;
        this.agentsPagination.pagesCount = Math.ceil(
          resp.data.meta.records / this.agentsPagination.limit
        );
        this.promises.agents = { fetching: false };
      } catch (e) {
        this.promises.agents = { fetching: false };
        throw Error(e);
      }
    },
  },
});
