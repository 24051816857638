import { defineStore } from "pinia";
import { axiosService } from "@/services/baseService";
import { websocketStore } from "./websocketStore";
import { ROLES } from "../constants/global";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      isAuthentificated: true,
      baseUser: JSON.parse(localStorage.getItem("user")),
      user: null,
      history: null,
      historyPagination: {
        limit: 8,
        offset: 0,
        page: 1,
        count: 0,
        pagesCount: 0,
      },
      promises: {
        history: {
          fetching: false,
        },
      },
      dispositioningData: null,
    };
  },
  getters: {
    role(state) {
      return state.baseUser.roles?.find((z) => z.includes(ROLES.manager))
        ? ROLES.manager
        : ROLES.agent;
    },
  },
  actions: {
    setBaseUser(payload) {
      this.baseUser = { ...payload };
    },
    async fetchUser() {
      try {
        const resp = await axiosService.get(`api/user/${this.baseUser.uuid}`, {
          auth: true,
        });
        this.user = { ...resp.data };
      } catch (e) {
        throw Error(e);
      }
    },
    async fetchHistory(page) {
      if (page === this.historyPagination.page) return;

      try {
        this.promises.history = { fetching: true };
        if (page) {
          this.historyPagination = {
            ...this.historyPagination,
            offset: (page - 1) * this.historyPagination.limit,
            page,
          };
        }
        const query = `?limit=${this.historyPagination.limit}&offset=${this.historyPagination.offset}`;
        const resp = await axiosService.get(
          `api/user/${this.baseUser.uuid}/history${query}`,
          { auth: true }
        );
        this.history = resp.data.data;
        this.historyPagination.count = resp.data.meta.records;
        this.historyPagination.pagesCount = Math.ceil(
          resp.data.meta.records / this.historyPagination.limit
        );
        this.promises.history = { fetching: false };
      } catch (e) {
        this.promises.history = { fetching: false };
        throw Error(e);
      }
    },
    async saveUser(payload) {
      try {
        await axiosService.patch(`api/user/${this.baseUser.uuid}`, {
          ...payload,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async fetchDispositioningData() {
      const useWebsocketStore = websocketStore();
      const response = await axiosService.get(
        `api/disposition/${useWebsocketStore.callId}`,
        {
          auth: true,
        }
      );
      console.log(response.data);
      try {
        if (response.data.contact.more === null) {
          response.data.contact.more = {};
        }
      } catch (e) {
        console.log(response.data);
      }
      this.dispositioningData = response.data;
    },
  },
});
