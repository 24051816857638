<template>
  <div class="dropdown-buttons">
    <div class="dropdown-buttons__row">
      <button
        v-if="dispositions.good"
        type="button"
        class="btn base-button rounded-circle btn-primary btn-icon-only"
        :class="{ active: activeIndex === 0 }"
        @click="dropdownHandler('good')"
      >
        <span class="btn-inner--icon">
          <i class="ni ni-like-2"></i>
        </span>
      </button>
      <button
        v-if="dispositions.bad"
        type="button"
        class="btn base-button rounded-circle btn-danger btn-icon-only"
        :class="{ active: activeIndex === 1 }"
        @click="dropdownHandler('bad')"
      >
        <span class="btn-inner--icon">
          <i class="ni ni-like-2" style="transform: rotate(180deg)"></i>
        </span>
      </button>
      <button
        v-if="dispositions.other"
        type="button"
        class="btn base-button rounded-circle btn-success btn-icon-only"
        :class="{ active: activeIndex === 2 }"
        @click="dropdownHandler('other')"
      >
        <span class="btn-inner--icon">
          <i class="bi bi-three-dots"></i>
        </span>
      </button>
    </div>
    <Transition>
      <div
        v-if="showDropdown"
        class="dropdown-buttons__list"
        style="width: 260px"
      >
        <div
          v-for="(item, index) in activeList"
          :key="`${index}-${item}`"
          class="dropdown-buttons__list--item"
        >
          <button type="button" class="btn" @click="selectItem(item)">
            {{ item }}
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/store/userStore";

export default {
  props: {
    dispositions: {
      type: Object,
      default() {
        return {
          bad: ["Do not call", "Wrong number"],
          good: ["Enrolled", "Made Appointment"],
          other: ["Disconnected"],
        };
      },
    },
  },
  data() {
    return {
      showDropdown: false,
      activeKey: null,
      activeIndex: 0,
    };
  },
  computed: {
    activeList() {
      try {
        return this.dispositioningData.options.dispositions[this.activeKey];
      } catch (e) {
        return this.dispositions[this.activeKey];
      }
    },
    ...mapState(useUserStore, ["dispositioningData"]),
  },
  methods: {
    dropdownHandler(key) {
      this.showDropdown = this.activeKey !== key;
      this.activeKey = this.showDropdown ? key : null;
      this.activeIndex = ["good", "bad", "other"].indexOf(key);
    },
    selectItem(key) {
      // const payload = {};
      // payload[this.activeKey] = [key];
      // this.$emit("change", { ...payload });
      this.$emit("change", key);
      this.showDropdown = false;
      this.activeKey = null;
    },
    created() {
      console.log(this.dispositioningData);
    },
  },
};
</script>
