import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import VueFullscreen from "vue-fullscreen";
import ArgonDashboard from "./plugins/argon-dashboard";
import { axiosService } from "@/services/baseService";
import Maska from "maska";

import "element-plus/lib/theme-chalk/index.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "vue-select/dist/vue-select.css";
import "vue-toastification/dist/index.css";
import "daterangepicker/daterangepicker.css";

import Toast from "vue-toastification";

const options = { containerClassName: "ct-notification" };

const appInstance = createApp(App);
appInstance.config.globalProperties.$http = axiosService;
appInstance.use(router);
appInstance.use(createPinia());
appInstance.use(Toast, options);
appInstance.use(ArgonDashboard);
appInstance.use(VueFullscreen);
appInstance.use(Maska);
appInstance.mount("#app");
