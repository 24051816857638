<template>
  <div class="popup">
    <div class="popup-wr">
      <div class="popup-bg" @click="$emit('close')"></div>
      <div class="popup-inner">
        <h2 v-if="title" class="popup__title text-center">{{ title }}</h2>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
