<template>
  <popup title="Nguyen Shane" @close="closePopup" class="agent-popup">
    <hr />
    <Form @submit="submit">
      <base-input
        class="mb-3"
        :value="'Medicare Advantage'"
        alternative
        name="name"
        label="Vertical"
        placeholder="Vertical"
        disabled
      >
      </base-input>
      <base-input
        alternative
        class="mb-3"
        name="email"
        label="Accepted States"
        placeholder="Accepted States"
        disabled
      >
      </base-input>
      <base-input
        class="mb-3"
        alternative
        name="address"
        label="Current Status"
        placeholder="Current Status"
        disabled
      >
      </base-input>
      <div class="bottom-row">
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="closePopup"
        >
          Schedule
        </button>
        <button :disabled="isFetching" type="submit" class="btn btn-primary">
          Dispositions
        </button>
      </div>
    </Form>
  </popup>
</template>

<script>
import Popup from "./Popup.vue";
import { Form } from "vee-validate";
// import { axiosService } from "@/services/baseService";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: { Popup, Form },
  data() {
    return {
      isFetching: false,
    };
  },
  mounted() {
    document.body.classList.add("overflow");
  },
  beforeUnmount() {
    document.body.classList.remove("overflow");
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    submit() {
      toast.success("Agent added successfully");
      this.isFetching = true;
      this.closePopup();
      this.isFetching = false;
    },
  },
};
</script>
