import Chart from "chart.js/auto";
// import "./roundedCornersExtension";
import { CHART_COLORS } from "../../../constants/charts";

function randomScalingFactor() {
  return Math.round(Math.random() * 100);
}

export const ordersChart = function (chartId, data, color = "#fb6340") {
  let chart = null;
  const ctx = document.getElementById(chartId).getContext("2d");

  const createChart = () => {
    chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data?.labels || ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: color,
            data: data?.data || [25, 20, 30, 22, 17, 29],
            maxBarThickness: 10,
            borderRadius: 5,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
        },
        scales: {
          y: {
            grid: {
              borderDash: [2],
              borderDashOffset: [2],
              zeroLineColor: "#dee2e6",
              drawBorder: false,
              drawTicks: false,
              lineWidth: 1,
              zeroLineWidth: 1,
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
            },
            ticks: {
              beginAtZero: true,
              padding: 10,
              font: { size: 13, family: "Open Sans" },
              color: "#8898aa",
              callback: function (value) {
                if (!(value % 2)) {
                  return value;
                }
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              padding: 10,
              font: { size: 13, family: "Open Sans" },
              color: "#8898aa",
            },
          },
        },
      },
    });
  };

  createChart();

  return {
    updateChart(data) {
      chart.data.labels = data?.labels || [
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      chart.data.datasets[0].data = data?.data || [25, 20, 30, 22, 17, 29];
      chart.update();
    },
  };
};

export const salesChart = {
  createChart(chartId) {
    const ctx = document.getElementById(chartId).getContext("2d");

    new Chart(ctx, {
      type: "line",
      data: {
        labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Performance",
            tension: 0.4,
            borderWidth: 4,
            borderColor: "#5e72e4",
            pointRadius: 0,
            backgroundColor: "transparent",
            data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
            barPercentage: 1.6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "transparent",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 0,
                color: "#8898aa",
                font: { size: 13, family: "Open Sans" },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.0)",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 10,
                color: "#8898aa",
                font: { size: 13, family: "Open Sans" },
              },
            },
          ],
        },
        layout: {
          padding: 0,
        },
      },
    });
  },
};

export const dotsChart = {
  createChart(chartId) {
    const ctx = document.getElementById(chartId).getContext("2d");

    new Chart(ctx, {
      type: "line",
      data: {
        labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
          {
            label: "Performance",
            tension: 0.4,
            data: [10, 18, 28, 23, 28, 40, 36, 46, 52],
            pointRadius: 10,
            pointBackgroundColor: "#5e72e4",
            pointBorderColor: "#5e72e4",
            pointHoverRadius: 15,
            showLine: false,
            barPercentage: 1.6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "#e9ecef",
                zeroLineColor: "#e9ecef",
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 60,
                padding: 20,
                color: "#8898aa",
                font: { size: 13, family: "Open Sans" },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "rgba(29,140,248,0.0)",
                zeroLineColor: "transparent",
              },
              ticks: {
                padding: 10,
                color: "#8898aa",
                font: { size: 13, family: "Open Sans" },
              },
            },
          ],
        },
        layout: {
          padding: 0,
        },
      },
    });
  },
};

export const doughnutChart = {
  createChart(chartId) {
    const ctx = document.getElementById(chartId).getContext("2d");

    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Danger", "Warning", "Success", "Primary", "Info"],
        datasets: [
          {
            label: "Dataset 1",
            tension: 0.4,
            data: [
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
            ],
            backgroundColor: [
              "#f5365c",
              "#fb6340",
              "#2dce89",
              "#5e72e4",
              "#11cdef",
            ],
            showLine: false,
            barPercentage: 1.6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 83,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        scales: {
          display: false,
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "transparent",
                zeroLineColor: "transparent",
              },
              ticks: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: "transparent",
                zeroLineColor: "transparent",
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
        layout: {
          padding: 0,
        },
      },
    });
  },
};

export const pieChart = (chartId, data) => {
  const ctx = document.getElementById(chartId).getContext("2d");
  let chart = null;

  const createChart = () => {
    chart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: data.labels,
        datasets: [
          {
            label: "Dispositions",
            data: data.data,
            backgroundColor: CHART_COLORS.map((z) => z.color),
            showLine: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 0,
        plugins: {
          legend: {
            display: false,
          },
        },
        layout: {
          padding: 0,
        },
      },
    });
  };
  createChart();

  return {
    updateChart(data) {
      chart.data.datasets[0].data = data.data;
      chart.update();
    },
  };
};

export const barChartStacked = (chartId, data) => {
  const ctx = document.getElementById(chartId).getContext("2d");
  let chart = null;

  const createChart = () => {
    chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data?.labels || [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: data?.datasets.map((z, i) => ({
          ...z,
          maxBarThickness: 120,
          tension: 0.4,
          backgroundColor: CHART_COLORS[i].color,
          borderRadius: 5,
        })) || [
          {
            label: "Dataset 1",
            maxBarThickness: 10,
            tension: 0.4,
            data: [
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
            ],
            backgroundColor: "#f5365c",
          },
          {
            label: "Dataset 2",
            maxBarThickness: 10,
            tension: 0.4,
            data: [
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
              randomScalingFactor(),
            ],
            backgroundColor: "#5e72e4",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 0,
        barPercentage: 1.6,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
        },
        scales: {
          y: {
            stacked: true,
            grid: {
              borderDash: [2],
              borderDashOffset: [2],
              zeroLineColor: "#dee2e6",
              drawBorder: false,
              drawTicks: false,
              lineWidth: 1,
              zeroLineWidth: 1,
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
            },
            ticks: {
              beginAtZero: true,
              padding: 10,
              font: { size: 13, family: "Open Sans" },
              color: "#8898aa",
              callback: function (value) {
                if (!(value % 2)) {
                  return value;
                }
              },
            },
          },
          x: {
            stacked: true,
            beginAtZero: true,
            grid: {
              drawBorder: false,
              color: "transparent",
              zeroLineColor: "transparent",
            },
            ticks: {
              font: { size: 13, family: "Open Sans" },
              color: "#8898aa",
            },
          },
        },
        layout: {
          padding: 0,
        },
      },
    });
  };
  createChart();

  return {
    updateChart(data) {
      chart.data.datasets = data?.datasets.map((z, i) => ({
        ...z,
        maxBarThickness: 120,
        tension: 0.4,
        backgroundColor: CHART_COLORS[i].color,
        borderRadius: 5,
      }));
      chart.update();
    },
  };
};

const funcs = {
  ordersChart() {},
  salesChart() {},
  dotsChart() {},
  doughnutChart() {},
  pieChart() {},
  barChartStacked() {},
};

export default funcs;
