<template>
  <AgentOverview  v-if="user.roles.includes('AGENT')" />
  <ManagerOverview v-if="user.roles.includes('MANAGER')" />
</template>

<script>
import AgentOverview from "./AgentOverview.vue";
import ManagerOverview from "./ManagerOverview.vue";
import { ROLES } from "../../constants/global";
import { mapState } from "pinia";
import { useUserStore } from "../../store/userStore";

export default {
  components: { AgentOverview, ManagerOverview },
  data() {
    return {
      ROLES,
    };
  },
  computed: {
    ...mapState(useUserStore, ["role", "user"]),
  },
};
</script>
