<template>
  <div>
    <v-select
      class="add-payment-dropdown"
      :value="value"
      item-value="id"
      v-model="value"
      :options="payments"
      :searchable="false"
      placeholder="Payment method"
      :clearable="false"
      @option:selected="changeHandler"
    >
      <template #selected-option="option">
        <div class="add-payment-dropdown__slot">
          <div class="add-payment-dropdown__logo">
            <img
              v-if="option.card.brand === 'visa'"
              src="../../assets/images/icons/visa.png"
              :alt="option.card.brand"
            />
            <img
              v-else-if="option.card.brand == 'mastercard'"
              src="../../assets/images/icons/mc.png"
              :alt="option.card.brand"
            />
          </div>
          <div class="add-payment-dropdown__content">
            <div class="add-payment-dropdown__name">
              {{ option.billing_details.name }}
            </div>
            <div class="add-payment-dropdown__card">
              {{ option.card.last4 }} {{ option.card.exp_month }}/{{
                slicedYear(option.card.exp_year)
              }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:option="option">
        <div class="add-payment-dropdown__slot">
          <div class="add-payment-dropdown__logo">
            <img
              v-if="option.card.brand === 'visa'"
              src="../../assets/images/icons/visa.png"
              :alt="option.card.brand"
            />
            <img
              v-else-if="option.card.brand == 'mastercard'"
              src="../../assets/images/icons/mc.png"
              :alt="option.card.brand"
            />
          </div>
          <div class="add-payment-dropdown__content">
            <div class="add-payment-dropdown__name">
              {{ option.billing_details.name }}
            </div>
            <div class="add-payment-dropdown__card">
              {{ option.card.last4 }} {{ option.card.exp_month }}/{{
                slicedYear(option.card.exp_year)
              }}
            </div>
          </div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePaymentsStore } from "../../store/paymentsStore";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  data() {
    return {
      selectedPayment: null,
    };
  },
  computed: {
    ...mapState(usePaymentsStore, ["payments"]),
    value: {
      get() {
        return this.selectedPayment === null
          ? this.payments.find((z) => z.default)
          : this.selectedPayment;
      },
      set(val) {
        this.selectedPayment = val;
      },
    },
  },
  methods: {
    ...mapActions(usePaymentsStore, ["setDefaultPayment"]),
    slicedYear(value) {
      return value.toString().slice(-2);
    },
    async changeHandler({ id }) {
      try {
        await this.setDefaultPayment(id);
        toast.success("Data updated");
      } catch (e) {
        toast.error("Data saving error");
      }

      console.log("changeHandler: ", id);
    },
  },
};
</script>
