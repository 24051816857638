<template>
  <popup @close="closePopup" class="remove-agent-popup text-center">
    <img src="../../assets/images/icons/danger.svg" alt="!" />
    <h2 class="h1 title">Are you sure you want to remove <b>John Doe?</b></h2>
    <p>You won’t be able to revert this!</p>
    <div class="bottom-row">
      <button
        type="button"
        class="btn btn-secondary"
        @click.prevent="closePopup"
      >
        Cancel
      </button>
      <button :disabled="isFetching" type="submit" class="btn btn-danger">
        Delete Agent
      </button>
    </div>
  </popup>
</template>

<script>
import Popup from "./Popup.vue";
// import { axiosService } from "@/services/baseService";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  components: { Popup },
  data() {
    return {
      isFetching: false,
    };
  },
  mounted() {
    document.body.classList.add("overflow");
  },
  beforeUnmount() {
    document.body.classList.remove("overflow");
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    submit() {
      toast.success("Agent added successfully");
      this.isFetching = true;
      this.closePopup();
      this.isFetching = false;
    },
  },
};
</script>
