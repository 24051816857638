import axios from "axios";
import {
  handleFailedResponse,
  handleRequestLogs,
  handleResponseLogs,
} from "../helpers/servcies";
import { createService } from "./baseServiceConfig";
import "dotenv/config";
export const API = axios.create({
  //baseURL: process.env.BASE_URL,
  baseURL: "https://api.voxba.ring2media.com",
  //baseURL: "http://localhost:3000",
  //baseURL: process.env.API_URL,
  // Do we need default timeout on FE?
  // timeout: window.APP.CONFIGURATION.REQUEST_TIMEOUT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // TODO: remove hardcode
    // "X-Access-Token": localStorage.getItem("token"),
    // "X-Access-Token":
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiZDhiNGVjMTEtNDlmZi00ZGNiLTg3ODgtZGMzMjI3NWViZmQyIiwiaWF0IjoxNjU0MDM0NDk2LCJleHAiOjE2NTQxMjA4OTZ9.f3u0ovBlJRfb9qG2lZHG6XmKHB0Vqc_-IEybTcR5BGg",
  },
});
console.log(process.env);
API.interceptors.response.use(handleResponseLogs, handleFailedResponse);
API.interceptors.request.use(handleRequestLogs);

export const axiosService = createService(API);
