import { defineStore } from "pinia";
import { axiosService } from "@/services/baseService";

export const globalStore = defineStore("global", {
  state: () => {
    return {
      verticals: [],
    };
  },
  actions: {
    async fetchVerticals() {
      if (this.verticals && this.verticals.length) return;

      try {
        const resp = await axiosService.get(`api/verticals/unique`, {
          auth: true,
        });
        this.verticals = [...resp.data];
      } catch (e) {
        throw Error(e);
      }
    },
  },
});
