function generateCSV(data, name) {
  var blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  var link = document.createElement("a");
  const objectUrl = window.URL.createObjectURL(blob);
  link.href = objectUrl;
  link.download = `${name}.csv`;
  link.click();
  window.URL.revokeObjectURL(objectUrl);
}

export { generateCSV };
