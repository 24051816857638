<template>
  <div
    class="help-center text-white text-center fw-bolder"
    v-if="user?.roles && !user.roles.includes('MANAGER')"
  >
    <div class="help-center__icon">
      <img src="../../assets/images/icons/call.svg" alt="Phone" />
    </div>
    <base-button type="primary">{{ formattedNumber }}</base-button>
  </div>
  <div v-else-if="user?.roles" class="navbar-inner">
    <ul class="navbar-nav mb-md-3">
      <li class="nav-item" @click="copyInviteLink" style="cursor:pointer">
        <a aria-current="page" class=" nav-link"><span class="nav-link__icon"><img
        src="../../assets/images/sidebar-icons/overview.svg" alt="Overview"></span><span class="nav-link-text">Copy Invite Link</span></a></li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "pinia";
import { useUserStore } from "../../store/userStore";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "Number",
  data() {
    return {
      isFetching: false
    };
  },
  computed: {
    ...mapState(useUserStore, ["user"]),
    formattedNumber() {
      try {
        let number = this.user.number.replace(/[^\d]/g, "").replace("^1", "");
        return `(${number.substring(0, 3)}) ${number.substring(
          3,
          6
        )}-${number.substring(6, 10)}`;
      } catch (e) {
        return "No Number";
      }
    }
  },
  methods: {
    copyInviteLink() {
      const el = document.createElement("textarea");
      el.value = "https://voxba.ring2media.com/register/#" + this.user?.uuid;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      //sent toast event
      toast.success("Copied to clipboard");
    },
  },
  async created() {
    //await this.fetchVerticals();
    //this.events = this.setUserEvents();
    //this.states = this.setUserStates();
    //this.vertical = this.setUserVerticals();
  }
};
</script>
