<template>
  <popup title="Generate Invoice" @close="closePopup" class="popup">
    <select
      class="form-select-lg"
      style="width: 100%; margin-bottom: 0.5em; padding: 0.5em"
    >
      <optgroup label="Type of Call">
        <option value="prod_NPZMRCIBucsYIy" selected>
          Final Expense ($65/call)
        </option>
      </optgroup>
    </select>
    <div class="input-group">
      <span class="input-group-text">Number of Calls</span
      ><input
        class="form-control"
        type="text"
        inputmode="numeric"
        v-model="calls"
      /><button class="btn btn-primary" type="button" @click="submit">Send Invoice</button>
    </div>
    <hr />
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td class="text-end text-right"><strong>Subtotal</strong></td>
            <td>${{ subtotal }}</td>
          </tr>
          <tr>
            <td class="text-end text-right"><strong>Fees</strong></td>
            <td>${{ fees.toFixed(2) }}</td>
          </tr>
          <tr>
            <td class="text-end text-right"><strong>Total</strong></td>
            <td>${{ (subtotal + fees).toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bottom-row" style="flex-direction: row-reverse">
      <button
        type="button"
        class="btn btn-secondary"
        @click.prevent="closePopup"
      >
        Close
      </button>
    </div>
  </popup>
</template>

<script>
import Popup from "./Popup.vue";
import { Form } from "vee-validate";
// import { axiosService } from "@/services/baseService";
import { useToast } from "vue-toastification";
import { axiosService } from "@/services/baseService";
import { useUserStore } from "@/store/userStore";

const toast = useToast();

export default {
  components: { Popup, Form },
  data() {
    return {
      isFetching: false,
      calls: 10,
    };
  },
  mounted() {
    document.body.classList.add("overflow");
  },
  beforeUnmount() {
    document.body.classList.remove("overflow");
  },
  //add computed property for total
  computed: {
    total() {
      return this.subtotal + this.fees;
    },
    subtotal() {
      return this.calls * 65;
    },
    fees() {
      return this.subtotal * 0.05;
    },
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    submit() {
      //post to api/uuid/invoice
      const store = useUserStore();
      axiosService
        .post(`api/user/${store.baseUser.uuid}/invoice`, {
          quantity: this.calls,
          product: "prod_NPZMRCIBucsYIy",
        })
        .then(({ data }) => {
          // this is temporary
        });
    },
  },
};
</script>
