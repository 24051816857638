<template>
  <div class="dark-background login-container">
    <!-- Header -->
    <!-- <span

      >Ring2Media</span
    > -->

    <div
      class="header top-bg-gradient clip-path-bg h-50 py-7 py-lg-8 pt-lg-9"
    ></div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mt--9">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="logo-wrapper">
                <img src="@/assets/images/logo.png" />
              </div>
              <div class="text-start mb-4 sign-in">Sign in</div>
              <Form @submit="onSubmit" :validation-schema="schema">
                <base-input
                  class="mb-3"
                  alternative
                  name="username"
                  addon-left-icon="ni ni-email-83"
                  placeholder="Username"
                >
                </base-input>

                <base-input
                  alternative
                  class="mb-3"
                  name="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Password"
                >
                </base-input>

                <!-- <base-checkbox v-model="model.rememberMe"
                  >Remember me</base-checkbox
                > -->
                <div class="text-center">
                  <base-button
                    type="primary"
                    native-type="submit"
                    class="my-4 w-100 sign-in-btn"
                    >Sign in</base-button
                  >

                  <div
                    class="no-account d-flex"
                    style="
                      flex-direction: column;
                      justify-content: center;
                      gap: 10px;
                    "
                  >
                    <span class="text-danger" v-if="fetchError">
                      Username or password is wrong
                    </span>
                    <span> Don’t have an account? </span>
                    <router-link
                      style="color: #1da1f2; cursor: pointer"
                      to="/register"
                    >
                      Create account
                    </router-link>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import { axiosService } from "@/services/baseService";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { addLocalStorageItem } from "../../helpers/util";
import { useUserStore } from "../../store/userStore";
import router from "../../router";

export default {
  components: {
    Form,
  },
  setup() {
    const userStore = useUserStore();
    const model = reactive({
      username: "",
      password: "",
      rememberMe: false,
    });
    const fetchError = ref(false);
    async function onSubmit(values) {
      fetchError.value = false;
      let handler = () => {
        fetchError.value = true;
      };
      axiosService
        .post("api/auth/signin", { ...model, ...values })
        .then(({ data }) => {
          addLocalStorageItem("user", data);
          localStorage.setItem("token", data.accessToken);
          localStorage.setItem("refreshToken", data.refreshToken);
          userStore.setBaseUser(data);
          router.push({ name: "Overview" });
        }, handler);
    }

    const schema = Yup.object().shape({
      username: Yup.string().required().label("The Username"),
      password: Yup.string().min(5).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
      model,
      fetchError,
      userStore,
    };
  },
};
</script>

<style>
.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
}
.sign-in {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  color: #081a51 !important;
}

.sign-in-btn {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
</style>
