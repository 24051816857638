<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand bg-white position-sticky top-0"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <!-- Search form -->
    <form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      <!-- <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" placeholder="Search" type="text" />
        </div>
      </div>
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="false">×</span>
      </button> -->
    </form>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li v-if="user?.stripe" class="mr-4">
        <button v-if="user?.stripe" class="btn btn-primary" @click="showPopupHandler">
          <img src="../assets/images/icons/money.svg" alt="$" class="mr-2" />
          ${{ parseFloat(user?.balance || 0).toLocaleString("en-US") }}
        </button>
        <charge-popup v-if="showPopup" @close="showPopupHandler" />
      </li>
      <li v-else class="mr-4">
        <button v-if="user?.roles?.includes('MANAGER')" class="btn btn-primary">
          <img src="../assets/images/icons/money.svg" alt="$" class="mr-2" />
          Funded
        </button>
      </li>
      <li class="nav-item ml-2" style="padding-right: 0.25em">
        <!--<a
          class="nav-link header-nav__link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </a>-->
        Check History And Ensure All Calls are Dispositioned
      </li>
      <base-dropdown
        class="nav-item"
        tag="li"
        title-classes="nav-link header-nav__link"
        title-tag="a"
        icon="fa fa-bell"
        menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
      >
        <!-- Dropdown header -->
        <div class="px-3 py-3">
          <h6 class="text-sm text-muted m-0">
            You have <strong class="text-primary">1</strong> notifications.
          </h6>
        </div>
        <!-- List group -->
        <div class="list-group list-group-flush">
          <a href="#!" class="list-group-item list-group-item-action">
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <img
                  alt="Image placeholder"
                  :src="user?.image"
                  class="avatar rounded-circle"
                />
              </div>
              <div class="col ml--2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h4 class="mb-0 text-sm">Welcome Aboard</h4>
                  </div>
                  <div class="text-right text-muted">
                    <small>...</small>
                  </div>
                </div>
                <p class="text-sm mb-0">
                  Welcome to the platform. Please remember to disposition your
                  calls by clicking on one of the icons at the bottom of the
                  form and selecting a disposition.
                </p>
              </div>
            </div>
          </a>
        </div>
        <!-- View all -->
        <!--<a
          href="#!"
          class="dropdown-item text-center text-primary font-weight-bold py-3"
          >View all</a
        >-->
      </base-dropdown>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" :src="user?.image" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 font-weight-semibold">
                  {{ baseUser.username }}
                </span>
              </div>
            </div>
          </a>
        </template>

        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">Welcome!</h6>
        </div>
        <!--<a href="#!" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>My profile</span>
        </a>-->
        <router-link class="dropdown-item" :to="{ name: 'Overview' }">
          <i class="ni ni-single-02"></i>
          <span>Overview</span>
        </router-link>
        <router-link class="dropdown-item" :to="{ name: 'History' }">
          <i class="ni ni-calendar-grid-58"></i>
          <span>History</span>
        </router-link>
        <router-link class="dropdown-item" :to="{ name: 'Settings' }">
          <i class="ni ni-settings-gear-65"></i>
          <span>Settings</span>
        </router-link>
        <!--<a href="#!" class="dropdown-item">
          <i class="ni ni-support-16"></i>
          <span>Support</span>
        </a>-->
        <div class="dropdown-divider"></div>
        <button type="button" class="dropdown-item" @click.prevent="logout">
          <i class="ni ni-user-run"></i>
          <span>Logout</span>
        </button>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import { useUserStore } from "../store/userStore";
import { mapState } from "pinia";
import { ROLES } from "../constants/global";
import ChargePopup from "../components/Popups/ChargePopup";
import AddAgentPopup from "@/components/Popups/AddAgentPopup.vue";

let timer;

export default {
  components: {
    AddAgentPopup,
    BaseNav,
    ChargePopup,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    ...mapState(useUserStore, ["baseUser", "user", "role"]),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      showPopup: false,
      ROLES,
    };
  },
  mounted() {
    window.addEventListener("resize", this.toggleSidebarOnResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.toggleSidebarOnResize);
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebarOnResize() {
      clearTimeout(timer);
      timer = setTimeout(() => {
        console.log("change");
        if (window.innerWidth < 1200) {
          this.hideSidebar();
        } else {
          this.showSidebar();
        }
      }, 400);
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.$router.push({ name: "Login" });
    },
    showPopupHandler() {
      this.showPopup = !this.showPopup;
    },
  },
};
</script>
