<template>
  <popup title="Add New Agent" @close="closePopup" class="add-agent-popup">
    <hr />
    <Form @submit="submit">
      <base-input
        v-model="agent.name"
        @input="(e) => (agent.name = e.target.value)"
        class="mb-3"
        alternative
        name="name"
        label="Agent Name"
        placeholder="Agent Name"
      >
      </base-input>
      <base-input
        v-model="agent.user_name"
        @input="(e) => (agent.user_name = e.target.value)"
        class="mb-3"
        alternative
        name="username"
        label="Agent Username"
        placeholder="Agent Username"
      >
      </base-input>
      <base-input
        v-model="agent.number"
        @input="(e) => (agent.number = e.target.value)"
        class="mb-3"
        alternative
        name="number"
        label="Agent Number"
        placeholder="Agent Number"
      >
      </base-input>
      <base-input
        v-model="agent.email"
        @input="(e) => (agent.email = e.target.value)"
        alternative
        class="mb-3"
        name="email"
        label="Email Address"
        placeholder="Enter agent email"
      >
      </base-input>
      <base-input
        v-model="agent.password"
        @input="(e) => (agent.password = e.target.value)"
        alternative
        type="password"
        class="mb-3"
        name="password"
        label="Password"
        placeholder="Enter Password"
      >
      </base-input>
      <div class="form-group mb-3">
        <label class="form-control-label">Vertical</label>
        <v-select
          multiple
          v-model="vertical"
          :options="verticalOptions"
          label="name"
          value="uuid"
          :closeOnSelect="false"
          placeholder="Select"
        ></v-select>
      </div>
      <div class="form-group mb-3">
        <label class="form-control-label">Accepted States</label>
        <v-select
          multiple
          v-model="states"
          :options="statesOption"
          :closeOnSelect="false"
          placeholder="Select"
        ></v-select>
      </div>
      <div class="bottom-row">
        <button
          type="button"
          class="btn btn-secondary"
          @click.prevent="closePopup"
        >
          Cancel
        </button>
        <button :disabled="isFetching" type="submit" class="btn btn-primary">
          Add
        </button>
      </div>
    </Form>
  </popup>
</template>

<script>
import Popup from "./Popup.vue";
import { Form } from "vee-validate";
// import { axiosService } from "@/services/baseService";
import { useToast } from "vue-toastification";
import { STATES } from "@/constants/global";
import { mapActions, mapState } from "pinia";
import { globalStore } from "@/store/globalStore";
import { useUserStore } from "@/store/userStore";
import { axiosService } from "@/services/baseService";
import { useAgentsStore } from "@/store/agentsStore";

const toast = useToast();

export default {
  components: { Popup, Form },
  props: {},
  data() {
    return {
      isFetching: false,
      vertical: [],
      states: [],
      STATES_CONST: STATES,
      agent: {
        name: "",
        user_name: "",
        number: "",
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    document.body.classList.add("overflow");
  },
  beforeUnmount() {
    document.body.classList.remove("overflow");
  },
  methods: {
    ...mapActions(globalStore, ["fetchVerticals"]),
    ...mapActions(useAgentsStore, ["fetchAgents"]),
    setUserVerticals() {
      try {
        return this.agent.settings.verticals;
      } catch (e) {
        console.error("error: mapping user verticals failed");
        return [];
      }
    },
    closePopup() {
      this.$emit("close");
    },
    submit() {
      let handler = (res) => toast.error(res?.response.data?.message);
      axiosService.post("api/auth/signup", this.newAgent).then(({ data }) => {
        // this is temp
        toast.success("Agent added successfully");
        this.closePopup();
        this.fetchAgents();
      }, handler);
    },
  },
  computed: {
    ...mapState(globalStore, ["verticals"]),
    verticalOptions() {
      return this.verticals.filter(
        (z) => !this.vertical.find((i) => z.uuid === i.uuid)
      );
    },
    statesOption() {
      return this.STATES_CONST.filter((z) => !this.states.includes(z));
    },
    newAgent() {
      return {
        name: this.agent.name,
        username: this.agent.user_name,
        number: this.agent.number,
        email: this.agent.email,
        password: this.agent.password,
        manager: useUserStore().baseUser.uuid,
        settings: this.agentSettings,
        roles: ["user", "agent"],
      };
    },
    agentSettings() {
      return {
        filters: {
          hours: {
            friday: [{ open: "08:00", close: "18:00" }],
            monday: [{ open: "08:00", close: "18:00" }],
            sunday: [{ open: "08:00", close: "18:00" }],
            tuesday: [{ open: "08:00", close: "18:00" }],
            saturday: [{ open: "08:00", close: "18:00" }],
            thursday: [{ open: "08:00", close: "18:00" }],
            wednesday: [{ open: "08:00", close: "18:00" }],
          },
          states: this.states,
        },
        preferences: {
          preferred: {
            states: [this.states[0]],
          },
        },
        verticals: this.vertical,
      };
    },
  },
  async created() {
    await this.fetchVerticals();
  },
};
</script>
