<template>
  <ManagerReports v-if="role === ROLES.manager" />
  <AgentReports v-else />
</template>

<script>
import AgentReports from "./AgentReports.vue";
import ManagerReports from "./ManagerReports.vue";
import { ROLES } from "../../constants/global";
import { mapState } from "pinia";
import { useUserStore } from "../../store/userStore";

export default {
  components: { AgentReports, ManagerReports },
  data() {
    return {
      ROLES,
    };
  },
  computed: {
    ...mapState(useUserStore, ["role"]),
  },
};
</script>
