export function setSessionStorageItem(key, value) {
  window.sessionStorage.setItem(key, value);
}
export function getSessionStorageItem(key) {
  return window.sessionStorage.getItem(key);
}

export function removeSessionStorageItem(key) {
  return window.sessionStorage.removeItem(key);
}

export const removeSessionStorageMultipleItems = (keys) => {
  keys.forEach((key) => window.sessionStorage.removeItem(key));
};

export const addLocalStorageItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const addLocalStorageMultipleItems = (items) => {
  items.forEach((item) => window.localStorage.setItem(item.key, item.value));
};

export const getLocalStorageItem = (key) => {
  return window.localStorage.getItem(key);
};

export const removeLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
};

export const removeLocalStorageMultipleItems = (keys) => {
  keys.forEach((key) => window.localStorage.removeItem(key));
};
