import { createRouter, createWebHistory } from "vue-router";
import { getLocalStorageItem } from "@/helpers/util";
import HomePageLayout from "@/layout/DashboardLayout";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import Overview from "../views/Overview";
import History from "../views/History";
import Reports from "../views/Reports";
import Agents from "../views/Agents";
import Settings from "../views/Settings";

const routes = [
  {
    path: "/",
    component: HomePageLayout,
    redirect: "/overview",
    name: "HomePageLayout",
    children: [
      {
        path: "/overview",
        name: "Overview",
        component: Overview,
      },
      {
        path: "/history",
        name: "History",
        component: History,
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
      },
      {
        path: "/agents",
        name: "Agents",
        component: Agents,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    components: { default: Login },
  },
  {
    path: "/register",
    name: "Register",
    components: { default: Register },
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to) => {
  if (
    !JSON.parse(getLocalStorageItem("user")) &&
    to.name !== "Login" &&
    to.name !== "Register"
  ) {
    return { name: "Login" };
  }
});

export default router;
