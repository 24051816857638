<template>
  <card type="white" header-classes="bg-transparent" class="status-bar">
    <div class="status-bar__status">
      <span class="status-bar__status--title">Current Status:</span>
      <div v-if="status === STATUSES.inactive" class="btn btn-danger">
        <img
          src="../../assets/images/icons/inactive.svg"
          alt="INACTIVE"
        />INACTIVE
      </div>
      <button
        v-else-if="status === STATUSES.offline"
        class="btn btn-danger"
        v-on:click="unPause"
      >
        <img src="../../assets/images/icons/inactive.svg" alt="PAUSED" />OFFLINE
      </button>
      <button
        v-else-if="status === STATUSES.paused"
        class="btn btn-danger"
        v-on:click="unPause"
      >
        <img src="../../assets/images/icons/inactive.svg" alt="PAUSED" />PAUSED
      </button>
      <button
        v-else-if="status === STATUSES.ready"
        class="btn btn-success"
        v-on:click="pause"
      >
        <img src="../../assets/images/icons/call.svg" alt="READY" />READY
      </button>
      <div v-else-if="status === STATUSES.connected" class="btn btn-success">
        <img src="../../assets/images/icons/call.svg" alt="ON CALL" />ON CALL
      </div>
      <div v-else-if="status === STATUSES.cooling" class="btn btn-primary">
        <img src="../../assets/images/icons/call.svg" alt="ON CALL" />COOLING
      </div>
      <div v-else-if="status === STATUSES.bidding" class="btn btn-primary">
        <img src="../../assets/images/icons/call.svg" alt="BIDDING" />BIDDING
      </div>
      <div
        v-else-if="status === STATUSES.disposition"
        class="btn btn-danger bg-pink"
      >
        <img
          src="../../assets/images/icons/inactive.svg"
          alt="DISPOSITIONING"
        />DISPOSITIONING
      </div>
    </div>
    <toggle-status />
  </card>
</template>

<script>
import ToggleStatus from "./ToggleStatus.vue";
import { STATUSES } from "@/constants/global";
import { useUserStore } from "@/store/userStore";
//import { axiosService } from "@/services/baseService";
import { mapActions } from "pinia";
import { websocketStore } from "@/store/websocketStore";

export default {
  name: "second-overview-header",
  components: {
    ToggleStatus,
  },
  props: {
    status: {
      type: String,
      default: "inactive",
      description:
        "Show whatever status is currently active: inactive|on-call|dispositioning,",
    },
  },
  data() {
    return {
      STATUSES: { ...STATUSES },
    };
  },
  methods: {
    ...mapActions(useUserStore, ["saveUser"]),
    ...mapActions(websocketStore, ["setStatus"]),
    async pause() {
      alert("pause");
      await this.saveUser({
        status: "paused",
      });
      await this.setStatus("paused");
    },
    async unPause() {
      await this.saveUser({
        status: "ready",
      });
      await this.setStatus("ready");
    },
  },
};
</script>
