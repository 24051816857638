<template>
  <div class="card">
    <div class="border-0 card-header d-flex justify-content-between">
      <h3 class="mb-0">{{ title }}</h3>
      <template v-if="headerLink">
        <a :href="headerLink.url" class="btn btn-primary btn-sm">
          {{ headerLink.title }}
        </a>
      </template>
    </div>

    <div class="position-relative">
      <Preloader v-if="promises.history.fetching"></Preloader>
      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        :data="projects"
        :defaultExpandAll="true"
      >
        <el-table-column
          label="InBound Call Id"
          prop="call_id"
          min-width="300px"
        >
        </el-table-column>

        <el-table-column label="Contact" prop="contact.name" min-width="120px">
          <template v-slot="{ row }">{{
            row.contact && row.contact.name ? `${row.contact.name}` : `-`
          }}</template>
        </el-table-column>

        <el-table-column label="State" prop="state" min-width="80px">
        </el-table-column>

        <el-table-column label="Target Number" min-width="170px" prop="status">
          <template v-slot="{ row }">
            <!-- <badge class="badge-dot mr-4" type="">
            <i :class="`bg-${row.statusType}`"></i>
            <span class="status">{{ row.status }}</span>
          </badge> -->
            {{ row.contact?.inbound_number }}
          </template>
        </el-table-column>

        <el-table-column label="Duraton" min-width="120px">
          <template v-slot="{ row }">
            {{ duration(row.call_start, row.call_end) }}
          </template>
        </el-table-column>

        <el-table-column label="Status" prop="completion" min-width="150px">
          <template v-slot="{ row }">
            <base-button
              v-if="row.status === 'completed'"
              type="primary"
              size="sm"
              tag="span"
              @click="dispositionCall(row.call_id)"
            >
              {{ row.status }}
            </base-button>
            <base-button
              v-else-if="row.status === 'connected'"
              type="success"
              size="sm"
              @click="dispositionCall(row.call_id)"
            >
              {{ row.status }}
            </base-button>
          </template>
        </el-table-column>
        <!--<el-table-column label="Payout" prop="payout" min-width="120px">
          <template v-slot="{ row }">{{
            row.payout ? `$${row.payout}` : `-`
          }}</template>
        </el-table-column>-->
        <el-table-column
          label="Disposition"
          prop="disposition"
          min-width="180px"
        >
        </el-table-column>
      </el-table>
    </div>

    <base-pagination
      v-if="historyPagination.pagesCount > 1"
      v-model="currentPage"
      :page-count="historyPagination.pagesCount"
      first-number
      last-number
      :class="{ fetching: promises.history.fetching }"
      @update:modelValue="paginationHandler"
    ></base-pagination>
  </div>
</template>

<script>
// import Badge from "@/components/Badge.vue";
import { DateTime } from "luxon";
import {
  ElTable,
  ElTableColumn,
  ElDropdown,
  ElDropdownMenu,
} from "element-plus";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "../store/userStore";
import Preloader from "./Preloader.vue";
import { websocketStore } from "@/store/websocketStore";
export default {
  name: "light-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Preloader,
  },
  props: {
    itemLimit: {
      type: Number,
      default: 5,
    },
    title: {
      type: String,
      default: "Recent Calls",
    },
    headerLink: {
      type: Object,
    },
    projects: {
      type: Array,
      default() {
        return [
          // {
          //   call_id: "x_call_id_1",
          //   agent: "d8b4ec11-49ff-4dcb-8788-dc32275ebfd2",
          //   contact: {
          //     inbound_number: "+1 555-123-4567",
          //   },
          //   call_start: "2022-05-19T21:44:55.869Z",
          //   call_end: "2022-05-19T22:47:59.869Z",
          //   payout: 20,
          //   disposition: "Sold Policy",
          //   status: "Sold",
          // },
        ];
      },
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    ...mapState(useUserStore, ["historyPagination", "promises"]),
  },
  created() {
    this.currentPage = this.historyPagination.page;
  },
  methods: {
    ...mapActions(useUserStore, ["fetchHistory", "fetchDispositioningData"]),
    ...mapActions(websocketStore, [
      "setConnection",
      "setStatus",
      "setUser",
      "setCallId",
    ]),
    duration(call_start, call_end) {
      if (!call_start || !call_end) return "-";

      const start = DateTime.fromISO(call_start);
      const end = DateTime.fromISO(call_end);
      const diff = end.diff(start, ["hours", "minutes", "seconds"]).toObject();
      const parseTime = (val) =>
        val < 10 ? `0${Math.ceil(val)}` : Math.ceil(val);
      return `${parseTime(diff.hours)}:${parseTime(diff.minutes)}:${parseTime(
        diff.seconds
      )}`;
    },
    paginationHandler(page) {
      this.fetchHistory(page);
    },
    dispositionCall(callId) {
      this.setStatus("disposition");
      this.setCallId(callId);
      this.fetchDispositioningData();
      return true;
    },
  },
};
</script>
