<template>
  <div class="card">
    <div
      class="border-0 card-header d-flex justify-content-between align-items-center"
    >
      <h3 class="mb-0">{{ title }}</h3>

      <template v-if="headerLink">
        <div class="d-flex align-items-center">
          <base-input
            type="text"
            name="contacts-search"
            placeholder="Search..."
            class="mb-0 mr-4"
            addon-left-icon="fas fa-search"
            :value="reportsPagination.contacts.search"
            @input="searchHandler"
          />
          <button
            class="btn btn-primary btn-sm"
            @click.prevent="fetchReportsContactsCSV"
          >
            {{ headerLink.title }}
          </button>
        </div>
        <!-- <a :href="headerLink.url" class="btn btn-primary btn-sm">
          {{ headerLink.title }}
        </a> -->
      </template>
    </div>

    <div class="position-relative">
      <Preloader v-if="promises.reportsContacts.fetching"></Preloader>
      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        :data="reports.contacts"
        :defaultExpandAll="true"
      >
        <el-table-column label="Agent" prop="agent" min-width="320px">
        </el-table-column>
        <el-table-column label="Name" prop="name" min-width="120px">
        </el-table-column>
        <el-table-column label="Number" prop="inbound_number" min-width="150px">
        </el-table-column>
        <el-table-column label="Email" prop="email" min-width="170px">
        </el-table-column>
        <el-table-column label="Address" prop="address" min-width="170px">
        </el-table-column>
        <el-table-column label="City" prop="city" min-width="100px">
        </el-table-column>
        <el-table-column label="state" prop="state" min-width="80px">
        </el-table-column>
        <el-table-column label="zip" prop="zip" min-width="80px">
        </el-table-column>
      </el-table>
    </div>

    <base-pagination
      v-if="reportsPagination.contacts.pagesCount > 1"
      v-model="currentPage"
      :page-count="reportsPagination.contacts.pagesCount"
      first-number
      last-number
      :class="{ fetching: promises.reportsContacts.fetching }"
      @update:modelValue="paginationHandler"
    ></base-pagination>
  </div>
</template>

<script>
// import Badge from "@/components/Badge.vue";
import { DateTime } from "luxon";
import {
  ElTable,
  ElTableColumn,
  ElDropdown,
  ElDropdownMenu,
} from "element-plus";
import { mapActions, mapState } from "pinia";
import { useReportsStore } from "../store/reportsStore";
import Preloader from "./Preloader.vue";

export default {
  name: "light-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Preloader,
  },
  props: {
    itemLimit: {
      type: Number,
      default: 5,
    },
    title: {
      type: String,
      default: "Recent Calls",
    },
    headerLink: {
      type: Object,
    },
  },
  data() {
    return {
      currentPage: 1,
      timer: null,
    };
  },
  computed: {
    ...mapState(useReportsStore, ["reports", "reportsPagination", "promises"]),
  },
  created() {
    this.fetchReportsContacts();
    this.currentPage = this.reportsPagination.contacts.page;
  },
  methods: {
    ...mapActions(useReportsStore, [
      "fetchReportsContacts",
      "fetchReportsContactsCSV",
    ]),
    duration(call_start, call_end) {
      if (!call_start || !call_end) return "-";

      const start = DateTime.fromISO(call_start);
      const end = DateTime.fromISO(call_end);
      const diff = end.diff(start, ["hours", "minutes", "seconds"]).toObject();
      const parseTime = (val) =>
        val < 10 ? `0${Math.ceil(val)}` : Math.ceil(val);
      return `${parseTime(diff.hours)}:${parseTime(diff.minutes)}:${parseTime(
        diff.seconds
      )}`;
    },
    paginationHandler(page) {
      this.fetchReportsContacts(page);
    },
    searchHandler(e) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.currentPage = 1;
        const search = e.target.value;
        this.fetchReportsContacts(1, search);
      }, 300);
    },
  },
};
</script>
