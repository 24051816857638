<template>
  <div class="card">
    <div class="border-0 card-header d-flex justify-content-between">
      <h3 class="mb-0">{{ title }}</h3>
      <template v-if="headerLink">
        <div class="d-flex align-items-center">
          <base-input
            type="text"
            name="calls-search"
            placeholder="Search..."
            class="mb-0 mr-4"
            addon-left-icon="fas fa-search"
            :value="reportsPagination.calls.search"
            @input="searchHandler"
          />
          <button
            type="button"
            @click.prevent="fetchReportsCallsCSV"
            class="btn btn-primary btn-sm"
          >
            {{ headerLink.title }}
          </button>
        </div>
      </template>
    </div>

    <div class="position-relative">
      <Preloader v-if="promises.reportsCalls.fetching"></Preloader>
      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        :data="reports.calls"
        :defaultExpandAll="true"
      >
        <el-table-column
          label="InBound Call Id"
          prop="call_id"
          min-width="320px"
        >
        </el-table-column>
        <el-table-column
          label="Agent"
          prop="agent_record.name"
          min-width="320px"
        >
        </el-table-column>

        <el-table-column label="Call start" min-width="180px">
          <template v-slot="{ row }">
            {{ parseTime(row.call_start) }}
          </template>
        </el-table-column>

        <el-table-column label="Duraton" min-width="120px">
          <template v-slot="{ row }">
            {{ duration(row.call_start, row.call_end) }}
          </template>
        </el-table-column>

        <el-table-column label="Status" prop="completion" min-width="150px">
          <template v-slot="{ row }">
            <base-button
              v-if="row.status === 'completed'"
              type="primary"
              size="sm"
              tag="span"
            >
              {{ row.status }}
            </base-button>
            <base-button
              v-else-if="row.status === 'connected'"
              type="success"
              size="sm"
            >
              {{ row.status }}
            </base-button>
          </template>
        </el-table-column>
        <el-table-column label="State" prop="state" min-width="120px">
        </el-table-column>
        <el-table-column
          label="Disposition"
          prop="disposition"
          min-width="150px"
        >
        </el-table-column>
      </el-table>
    </div>

    <base-pagination
      v-if="reportsPagination.calls.pagesCount > 1"
      v-model="currentPage"
      :page-count="reportsPagination.calls.pagesCount"
      first-number
      last-number
      :class="{ fetching: promises.reportsCalls.fetching }"
      @update:modelValue="paginationHandler"
    ></base-pagination>
  </div>
</template>

<script>
// import Badge from "@/components/Badge.vue";
import { DateTime } from "luxon";
import {
  ElTable,
  ElTableColumn,
  ElDropdown,
  ElDropdownMenu,
} from "element-plus";
import { mapActions, mapState } from "pinia";
import { useReportsStore } from "../store/reportsStore";
import Preloader from "./Preloader.vue";

export default {
  name: "light-table",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Preloader,
  },
  props: {
    itemLimit: {
      type: Number,
      default: 5,
    },
    title: {
      type: String,
      default: "Recent Calls",
    },
    headerLink: {
      type: Object,
    },
    projects: {
      type: Array,
      default() {
        return [
          // {
          //   call_id: "x_call_id_1",
          //   agent: "d8b4ec11-49ff-4dcb-8788-dc32275ebfd2",
          //   contact: {
          //     inbound_number: "+1 555-123-4567",
          //   },
          //   call_start: "2022-05-19T21:44:55.869Z",
          //   call_end: "2022-05-19T22:47:59.869Z",
          //   payout: 20,
          //   disposition: "Sold Policy",
          //   status: "Sold",
          // },
        ];
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      timer: null,
    };
  },
  computed: {
    ...mapState(useReportsStore, ["reports", "reportsPagination", "promises"]),
  },
  created() {
    this.fetchReportsCalls();
    this.currentPage = this.reportsPagination.calls.page;
  },
  methods: {
    ...mapActions(useReportsStore, [
      "fetchReportsCalls",
      "fetchReportsCallsCSV",
    ]),
    duration(call_start, call_end) {
      if (!call_start || !call_end) return "-";

      const start = DateTime.fromISO(call_start);
      const end = DateTime.fromISO(call_end);
      const diff = end.diff(start, ["hours", "minutes", "seconds"]).toObject();
      const parseTime = (val) =>
        val < 10 ? `0${Math.ceil(val)}` : Math.ceil(val);
      return `${parseTime(diff.hours)}:${parseTime(diff.minutes)}:${parseTime(
        diff.seconds
      )}`;
    },
    paginationHandler(page) {
      this.fetchReportsCalls(page);
    },
    searchHandler(e) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.currentPage = 1;
        const search = e.target.value;
        this.fetchReportsCalls(1, search);
      }, 300);
    },
    parseTime(date) {
      const dt = DateTime.fromISO(date);
      return dt.toFormat("d.MM.y hh:mm a");
    },
  },
};
</script>
