/* eslint-disable prettier/prettier */
let fetchRequestTimestamp = new Date().getTime();

export const handleResponseLogs = (response) => {
  // const timeTakenByFetchApi = new Date().getTime() - fetchRequestTimestamp;
  // console.log(response);
  // console.log(`Fetch API operation completed in ${timeTakenByFetchApi} ms`);

  return response;
};
export const handleFailedResponse = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    location.reload();
  }
  var timeTakenByFetchApi = new Date().getTime() - fetchRequestTimestamp;
  let [url, response, errorMessage] = [
    error?.request?.responseURL,
    error?.response?.data,
    error?.message,
  ];

  console.error(
    `Fetch API operation failed in ${timeTakenByFetchApi} ms`,
    url,
    errorMessage,
    response
  );

  return Promise.reject(error);
};
export const handleRequestLogs = (config) => {
  fetchRequestTimestamp = new Date().getTime();
  return config;
};
