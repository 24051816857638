<template>
  <Form @submit="saveHandeler">
    <div class="settings-layout manager-settings">
      <div class="d-flex align-items-xl-center">
        <h2 class="page-title flex-grow-1">Settings</h2>
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-primary" :disabled="isFetching">
            Save Changes
          </button>
        </div>
      </div>
      <card noBody class="p-0">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 p-0 border-right">
              <card header-classes="bg-transparent" :shadow="true" class="mb-0">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="h3 mb-0">Account Settings</h5>
                    </div>
                  </div>
                </template>
                <div class="form-element" v-if="user">
                  <label class="col-form-label">ID</label>
                  <div class="form-element__input">
                    <div class="form-group">
                      <input
                        type="text"
                        disabled
                        :value="user?.uuid"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-element">
                  <label class="col-form-label">Name</label>
                  <div class="form-element__input">
                    <base-input
                      name="contacts.name"
                      type="text"
                      placeholder="John Snow"
                      :value="getUserContact('name')"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="form-element">
                  <label class="col-form-label">E-Mail</label>
                  <div class="form-element__input">
                    <base-input
                      name="contacts.email"
                      type="text"
                      placeholder="argon@example.com"
                      :value="getUserContact('email')"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="form-element">
                  <label class="col-form-label">Contact Phone</label>
                  <div class="form-element__input">
                    <base-input
                      name="contacts.phone"
                      type="text"
                      placeholder="555-123-4567"
                      :value="getUserContact('phone')"
                    >
                    </base-input>
                  </div>
                </div>
              </card>
              <card header-classes="bg-transparent" :shadow="true" class="mb-0">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="h3 mb-0">Capacity Settings</h5>
                    </div>
                  </div>
                </template>
                <div class="capacity__row mb-4">
                  <div class="capacity__col">
                    <div class="col-form-label title mb-4 pb-3">Daily</div>
                    <div class="form-element">
                      <label class="col-form-label">Spend</label>
                      <div class="form-element__input">
                        <base-input
                          name="daily_call_cap"
                          type="number"
                          :value="user?.settings?.caps?.daily?.volume || ''"
                        >
                        </base-input>
                      </div>
                    </div>
                    <div class="form-element">
                      <label class="col-form-label">Volume</label>
                      <div class="form-element__input">
                        <base-input
                          name="daily_spend_cap"
                          type="number"
                          :value="user?.settings?.caps?.daily?.spend || ''"
                        >
                        </base-input>
                      </div>
                    </div>
                  </div>
                  <div class="capacity__col">
                    <div class="col-form-label title mb-4 pb-3">Monthly</div>
                    <div class="form-element">
                      <label class="col-form-label">Spend</label>
                      <div class="form-element__input">
                        <base-input
                          name="monthly_call_cap"
                          type="number"
                          :value="user?.settings?.caps?.monthly?.volume || ''"
                        >
                        </base-input>
                      </div>
                    </div>
                    <div class="form-element">
                      <label class="col-form-label">Volume</label>
                      <div class="form-element__input">
                        <base-input
                          name="monthly_spend_cap"
                          type="number"
                          :value="user?.settings?.caps?.monthly?.spend || ''"
                        >
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-element">
                  <label class="col-form-label">Concurrency Cap</label>
                  <div class="form-element__input">
                    <base-input
                      name="concurrency"
                      type="text"
                      placeholder="48 hours"
                      :value="user?.settings?.caps?.concurrency?.volume || ''"
                    >
                    </base-input>
                  </div>
                </div>
              </card>
              <card header-classes="bg-transparent" :shadow="true" class="mb-0">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="h3 mb-0">Billing Settings</h5>
                    </div>
                  </div>
                </template>
                <div class="form-element mb-5">
                  <label class="col-form-label">Add Payment Method</label>
                  <div class="form-element__input add-disposition-settings">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="showAddPaymentHandler(true)"
                    >
                      <i class="ni ni-fat-add"></i> Add Payment
                    </button>
                    <!-- <button type="button" class="btn btn-primary btn-stripe">
                      Connect with Stripe
                    </button> -->
                  </div>
                </div>
                <div class="form-element mb-5">
                  <label class="col-form-label pt-3">
                    Default Payment Method
                  </label>
                  <div class="form-element__input">
                    <DefaultPaymentVue />
                  </div>
                </div>
                <div class="form-element mb-5">
                  <label class="col-form-label pt-0">Auto renew</label>
                  <div class="form-element__input">
                    <base-checkbox name="currency_cap">
                      Renew automatically
                    </base-checkbox>
                  </div>
                </div>
              </card>
            </div>
            <div
              class="col-md-6 p-0 d-flex flex-column justify-content-between"
            >
              <card header-classes="bg-transparent" :shadow="true" class="mb-0">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col">
                      <h5 class="h3 mb-0">Agent Disposition Settings</h5>
                    </div>
                  </div>
                </template>
                <add-options
                  ref="dispositions"
                  :dispositions="user?.settings?.ui?.options?.dispositions"
                />
              </card>
            </div>
          </div>
        </div>
      </card>

      <AddPaymentPopup
        v-if="showAddPayment"
        @close="showAddPaymentHandler(false)"
      />
    </div>
  </Form>
</template>

<script>
import "vue-cal/dist/drag-and-drop";
import "vue-cal/dist/vuecal.css";
import { DateTime } from "luxon";
import { WEEK } from "../../constants/calendar";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "../../store/userStore";
import { STATES } from "../../constants/global";
import { useToast } from "vue-toastification";
import { globalStore } from "../../store/globalStore";
import AddOptions from "../../components/AddOptions.vue";
import { Form } from "vee-validate";
import AddPaymentPopup from "../../components/Popups/AddPaymentPopup.vue";
import DefaultPaymentVue from "../../components/Paymets/DefaultPayment.vue";

const toast = useToast();

export default {
  name: "Settings",
  components: { AddOptions, Form, AddPaymentPopup, DefaultPaymentVue },
  data() {
    return {
      vertical: [],
      states: [],
      STATES_CONST: STATES,
      isFetching: false,
      showAddPayment: false,
    };
  },
  computed: {
    ...mapState(useUserStore, ["user"]),
    ...mapState(globalStore, ["verticals"]),
    verticalOptions() {
      return this.verticals.filter(
        (z) => !this.vertical?.find((i) => z.uuid === i.uuid)
      );
    },
    statesOption() {
      return this.STATES_CONST.filter((z) => !this.states.includes(z));
    },
    week() {
      const dt = DateTime.now();
      const week = WEEK.map((day, index) => ({
        key: day,
        date: dt
          .startOf("week")
          .plus({ days: index - 1 })
          .toFormat("yyyy-MM-dd"),
      }));

      return week;
    },
  },
  async created() {},
  methods: {
    ...mapActions(useUserStore, ["saveUser"]),
    async saveHandeler(val) {
      this.isFetching = true;
      try {
        const dispositions = this.$refs.dispositions;
        await this.saveUser({
          settings: {
            caps: {
              daily: {
                spend: val.daily_spend_cap,
                volume: val.daily_call_cap,
              },
              monthly: {
                spend: val.monthly_spend_cap,
                volume: val.monthly_call_cap,
              },
              concurrency: {
                volume: val.concurrency,
              },
            },
            ui: {
              options: {
                dispositions: {
                  bad: dispositions.bad
                    .filter((z) => z.value)
                    .map((z) => z.value),
                  good: dispositions.good
                    .filter((z) => z.value)
                    .map((z) => z.value),
                  other: dispositions.other
                    .filter((z) => z.value)
                    .map((z) => z.value),
                },
              },
              contact: [
                {
                  label: "name",
                  value: val.contacts.name,
                },
                {
                  label: "email",
                  value: val.contacts.email,
                },
                {
                  label: "phone",
                  value: val.contacts.phone,
                },
              ],
            },
          },
        });
        toast.success("Data updated");
        this.isFetching = false;
      } catch (error) {
        this.isFetching = false;
        toast.error("Data saving error");
        console.log("error: mapping shchedule");
      }
    },
    getUserContact(key) {
      if (!this.user?.settings?.ui?.contact) return undefined;
      return this.user?.settings?.ui?.contact.find((z) => z.label === key)
        .value;
    },
    showAddPaymentHandler(show) {
      this.showAddPayment = show;
    },
  },
};
</script>
