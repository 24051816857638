<template>
  <div class="dark-background login-container">
    <!-- Header -->
    <div
      class="header top-bg-gradient clip-path-bg h-50 py-7 py-lg-8 pt-lg-9"
    ></div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mt--9">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="logo-wrapper">
                <img src="@/assets/images/logo.png" />
              </div>
              <div class="text-start mb-4 register-title">Register</div>
              <Form @submit="onSubmit" :validation-schema="schema">
                <base-input
                  class="mb-3"
                  alternative
                  name="username"
                  addon-left-icon="ni ni-circle-08"
                  placeholder="Username"
                >
                </base-input>
                <base-input
                  alternative
                  class="mb-3"
                  name="email"
                  addon-left-icon="ni ni-email-83"
                  placeholder="Email"
                >
                </base-input>
                <div class="auth-select--wr">
                  <i class="bi bi-mortarboard-fill"></i>
                  <v-select
                    v-model="model.roles[0]"
                    :options="selects.roles"
                    placeholder="Roles"
                    class="select-danger mb-3 auth-select"
                    :reduce="(option) => option.value"
                  />
                </div>
                <!-- <el-select
                  class="select-danger mb-3"
                  placeholder="Roles"
                  v-model="model.roles[0]"
                  @change="handleRoleChange"
                  style="width: 100%"
                >
                  <el-option
                    v-for="option in selects.roles"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select> -->
                <base-input
                  alternative
                  class="mb-3"
                  name="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Password"
                >
                </base-input>
                <div class="form-check form-switch text-primary">
                  <input id="managed" class="form-check-input" type="checkbox" checked value="true" name="managed" v-model="model.managed" />
                  <label class="form-check-label" for="managed"
                    >R2M Managed</label
                  >
                </div>
                <div class="text-center">
                  <base-button
                    type="primary"
                    native-type="submit"
                    class="my-4 w-100"
                    >Create Account</base-button
                  >
                  <div class="text-start text-muted mb-2">
                    Already have an account
                  </div>
                  <router-link
                    style="color: #1da1f2; cursor: pointer"
                    class="text-start text-sm text-primary mb-4"
                    to="/login"
                  >
                    Sign In
                  </router-link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";

import { axiosService } from "@/services/baseService";
import { Form } from "vee-validate";
import * as Yup from "yup";

export default {
  name: "register",
  components: {
    Form,
  },
  setup() {
    const router = useRouter();
    const model = reactive({
      username: "",
      email: "",
      password: "",
      roles: window.location.hash.split("#").length < 2 ? [] : ["agent"],
      managed: window.location.hash.split("#").length < 2,
      manager: window.location.hash.split("#")[1],
    });

    const selects = reactive({
      default: "",
      roles: [
        // { label: "User", value: "user" },
        { label: "Agent", value: "agent" },
        { label: "Manager", value: "manager" },
        {
          label: "Independent Agent",
          value: "agent/manager",
        },
      ],
    });
    function onSubmit(values) {
      let handler = (res) => alert(res?.response.data?.message);
      console.log({
        ...model,
        ...values,
        roles: model.roles[0].split("/"),
      });
      axiosService
        .post("api/auth/signup", {
          ...model,
          ...values,
          roles: model.roles[0].split("/"),
        })
        .then(({ data }) => {
          // this is temp
          alert(data.message);
          alert("Redirecting to login");
          router.push("/login");
        }, handler);
    }

    const schema = Yup.object().shape({
      username: Yup.string().required().label("The Username"),
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().min(8).required().label("The Password"),
      roles: Yup.array().required().label("The Role"),
    });
    const handleRoleChange = (value) => {
      model.roles = [value];
    };
    return {
      onSubmit,
      schema,
      model,
      selects,
      handleRoleChange,
    };
  },
};
</script>

<style>
.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
}
.register-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  color: #081a51 !important;
}
</style>
