<template>
  <div>
    <h2 class="page-title">History</h2>
    <recent-calls-table title="Recent Calls" :projects="history" />
    <!-- <light-table title="RECENT CALLS" /> -->
  </div>
</template>

<script>
import RecentCallsTable from "@/components/RecentCallsTable";
import { useUserStore } from "../store/userStore";
import { mapState, mapActions } from "pinia";
import { websocketStore } from "@/store/websocketStore";

export default {
  name: "History",
  components: {
    RecentCallsTable,
  },
  data() {
    return {
      status: "on-call",
      showPopup: true,
    };
  },
  computed: {
    ...mapState(useUserStore, ["history"]),
  },
  created() {
    this.fetchHistory();
  },
  methods: {
    ...mapActions(useUserStore, ["fetchHistory"]),
    popupHandler(show) {
      this.showPopup = show;
    },
  },
};
</script>

<style lang="scss"></style>
